import React, { Component } from 'react';
import UserInfo from '../UserInfo';
import PostList from '../post/PostList';
import UserStore from '../../stores/UserStore';

class UserTab extends Component {
  onUserNotFound = () => {
    this.postList && this.postList.hide();
  };

  render() {
    const uid = this.props.match.params.id || UserStore.getCurrentUid();
    if (!uid) {
      UserStore.check_user_and_alert(true);
      this.props.history.push(`/settings`);
      return <div />;
    }

    if (uid && this.prevUid && uid !== this.prevUid) {
      this.props.history.push(`/users/${uid}`);
      this.userInfo && this.userInfo.loadUserInfo(uid);
      this.postList && this.postList.refreshPostList({ uid: uid });
    }
    this.prevUid = uid;

    return (
      <div>
        <UserInfo
          uid={uid}
          onRef={ref => (this.userInfo = ref)}
          showSummary={true}
          onNotFound={this.onUserNotFound.bind(this)}
        />
        <PostList hideLoading uid={uid} onRef={ref => (this.postList = ref)} />
      </div>
    );
  }
}
export default UserTab;
