import firebase from '../include/firebase_core';
import { BSImage } from '../res/BSImage';
import moment from 'moment';
import $ from 'jquery';

export class BeerStoryCore {
  static POST_LIMITS = 20;

  static beerMap;
  static breweryMap;
  static from_bs_app = false;
  static styles = {
    0: { id: 0, name: '-', desc: '선택 안함' },
    1: {
      id: 1,
      name: 'Larger(라거)',
      desc:
        '하면발효방식으로 생산되는 맥주의 한 종류. ‘저장’을 뜻하는 독일어 명칭에서 알 수 있듯이 저온에서 일정기간 숙성시킨 맥주를 일컫는다. (출처: 네이버 지식백과)',
    },
    2: { id: 2, name: 'Pale Larger(페일 라거)', desc: '', parent: 1 },
    3: { id: 3, name: 'Pilsner(필스너)', desc: '', parent: 1 },
    4: { id: 4, name: 'Amber Larger(엠버 라거)', desc: '', parent: 1 },
    5: { id: 5, name: 'Bock(복)', desc: '', parent: 1 },
    6: {
      id: 6,
      name: 'Ale(에일)',
      desc:
        '상면발효방식으로 생산되는 맥주의 한 종류. 표면 발효로 양조 되며, 보리 맥아를 사용하고 효모를 상온에서 단기간에 발효 시켜, 복잡한 향과 깊은 맛, 과일 맛을 만들어 낸다. 영국, 아일랜드, 벨기에, 독일, 캐나다 동부의 주 및 미국의 맥주에서 일반적이다. (출처: 위키피디아)',
    },
    7: { id: 7, name: 'Pale Ale(페일 에일)', desc: '', parent: 6 },
    8: {
      id: 8,
      name: 'I.P.A.(Indian Pale Ale)',
      desc: 'Indian Pale Ale',
      parent: 6,
    },
    9: { id: 9, name: 'Porter(포터)', desc: '', parent: 6 },
    10: { id: 10, name: 'Stout(스타우트)', desc: '', parent: 6 },
    11: { id: 11, name: 'Wheat Beer(위트 비어)', desc: '', parent: 6 },
    12: { id: 12, name: 'Etc(기타)', desc: '' },
  };
  static servingType = {
    0: { id: 0, name: '-', desc: '선택 안함', icon: '' },
    1: {
      id: 1,
      name: 'Bottle',
      desc: '병맥주',
      icon: BSImage['ic_serving_bottle'],
    },
    2: { id: 2, name: 'Can', desc: '캔맥주', icon: BSImage['ic_serving_can'] },
    3: {
      id: 3,
      name: 'Draft',
      desc: '생맥주',
      icon: BSImage['ic_serving_draft'],
    },
    // 3: {id: 3, name: 'Cask', desc: ''},
    // 4: {id: 4, name: 'Growler', desc: ''},
  };
  static flavors = [
    { id: 0, name: 'Alcohol', desc: '알콜 도수' },
    { id: 1, name: 'Malty', desc: '맥아의 맛' },
    { id: 2, name: 'Body', desc: '바디감 (ex:라이트,미디엄,풀바디)' },
    { id: 3, name: 'Sweet', desc: '단 맛' },
    { id: 4, name: 'Dry', desc: '드라이한 정도' },
    { id: 5, name: 'Hoppy', desc: '홉의 맛' },
    { id: 6, name: 'Linger', desc: '마신 후 입 안에 남는 맛의 정도' },
    { id: 7, name: 'Bitter', desc: '쓴 정도' },
  ];
  static defaultFlavors = {
    Alcohol: 0,
    Malty: 0,
    Body: 0,
    Sweet: 0,
    Dry: 0,
    Hoppy: 0,
    Linger: 0,
    Bitter: 0,
  };
  static getDefaultFlavors = defValue => {
    let flavors = {};
    for (var k in BeerStoryCore.defaultFlavors) {
      flavors[k] = defValue;
    }
    return flavors;
  };

  static beers = null;
  static breweries = null;
  static postList = null;

  static alert_server_connection = e => {
    if (!!e) {
      console.error(e);
    }
    $.notify(
      {
        title: 'Error',
        message: '서버와의 통신에 실패하였습니다. 잠시 후 다시 시도해주세요.',
      },
      { type: 'danger' }
    );
  };

  static not_available_now = () => {
    $.notify(
      {
        message: '현재 지원하지 않는 기능입니다.',
      },
      { type: 'info' }
    );
  };

  static getDateStringFormat(date) {
    return date ? moment(date).format('YYYY.MM.DD') : '';
  }

  static diffDate(date) {
    let postDate = new Date(date);
    let now = new Date();
    let diffTime = (now.getTime() - postDate.getTime()) / 1000 / 60 / 60;
    if (diffTime < 48) {
      let diffDate = now.getDate() - postDate.getDate();
      if (diffDate <= 0) {
        let diffHour = now.getHours() - postDate.getHours();
        if (diffHour <= 0) {
          let diffMin = now.getMinutes() - postDate.getMinutes();
          if (diffMin <= 0) {
            return '방금 전';
          } else {
            return `${diffMin}분 전`;
          }
        } else {
          return `${diffHour}시간 전`;
        }
      } else {
        var hour = postDate.getHours();
        if (hour < 10) hour = `0${postDate.getHours()}`;
        else hour = `${postDate.getHours()}`;
        var min = postDate.getMinutes();
        if (min < 10) min = `0${postDate.getMinutes()}`;
        else min = `${postDate.getMinutes()}`;
        return `어제 ${hour}:${min}`;
      }
    } else {
      return moment(postDate).format('YYYY.MM.DD'); //postDate.toISOString().substr(0, 10);
    }
  }

  static userFromSession = user => {
    var users = JSON.parse(sessionStorage.getItem('bs_user_temp'));
    if (
      !users ||
      !users.time ||
      new Date().getTime() - users.time > 1000 * 60 * 60 * 2 // session refresh after 2hr
    ) {
      users = { time: new Date().getTime() };
    }

    if (!user || !user.uid) return Promise.resolve(user);
    const uid = user.uid;
    if (users[uid]) return Promise.resolve(users[uid]);

    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .get()
        .then(data => {
          const user = data.data();
          users[uid] = user;
          sessionStorage.setItem('bs_user_temp', JSON.stringify(users));
          resolve(user);
        })
        .catch(e => {
          resolve(user);
        });
    });
  };
  static putUserToSession = user => {
    if (!user || !user.uid) return;
    var users = JSON.parse(sessionStorage.getItem('bs_user_temp'));
    if (!users) users = {};
    users[user.uid] = user;
    sessionStorage.setItem('bs_user_temp', JSON.stringify(users));
  };
}

export const loadBeerList = (forced = false, callback = null) => {
  if (!forced && !sessionStorage.getItem('bs_beers')) return;
  var beerRef = firebase.firestore().collection('beers');
  beerRef
    .get()
    .then(querySnapshot => {
      var beers = {};
      querySnapshot.forEach(doc => {
        beers[doc.id] = doc.data();
        beers[doc.id].id = doc.id;

        if (doc.data().nameEn.length <= 0 || doc.data().nameKr.length <= 0) {
          if (doc.data().nameEn.length > 0) {
            beers[doc.id].label = doc.data().nameEn;
            beers[doc.id].value = doc.data().nameEn;
          }
          if (doc.data().nameKr.length > 0) {
            beers[doc.id].label = doc.data().nameKr;
            beers[doc.id].value = doc.data().nameKr;
          }
        } else {
          beers[doc.id].label = `${doc.data().nameEn}-${doc.data().nameKr}`;
          beers[doc.id].value = `${doc.data().nameEn}-${doc.data().nameKr}`;
        }
      });
      console.log(beers);
      // this.props.beers = beers;
      // BeerStoryCore.beerArr = Object.values(beers);

      sessionStorage.setItem('bs_beers', JSON.stringify(beers));
      if (callback !== null) {
        callback.success(beers);
      }
    })
    .catch(e => {
      if (callback !== null) {
        callback.failed();
      }
    });
};
