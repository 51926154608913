import styled from 'styled-components';
import { Tabs } from 'react-bootstrap';
import { BSImage } from '../../res/BSImage';

export const ColumnContent = styled.div`
  margin: 0px auto;
  width: 80%;
  max-width: 600px;

  @media (max-width: 666px) {
    min-width: 90%;
  }

  border-left: ${props => (props.border ? '2px solid #c0c0c0' : 'none')};
  border-right: ${props => (props.border ? '2px solid #c0c0c0' : 'none')};
  border-bottom: ${props => (props.border ? '2px solid #c0c0c0' : 'none')};
  border-bottom-left-radius: ${props => (props.border ? '1em' : '0')};
  border-bottom-right-radius: ${props => (props.border ? '1em' : '0')};
  padding-bottom: ${props => (props.border ? '1em' : '0')};
  background: ${props =>
    props.border ? props.backgroundColor || 'white' : 'inherit'};
`;

export const ProfileImg = styled.img`
  border: 1px solid #ffc107;
  border-radius: 50%;
  vertical-align: center;
  background-color: white;
  min-width: ${props => (!isNaN(props.size) ? `${props.size}px` : '40px')};
  min-height: ${props => (!isNaN(props.size) ? `${props.size}px` : '40px')};
  width: ${props => (!isNaN(props.size) ? `${props.size}px` : '40px')};
  height: ${props => (!isNaN(props.size) ? `${props.size}px` : '40px')};
  ${props => !props.src && `content: url("${BSImage['profile_default']}");`}
`;
export const Blank = styled.div`
  display: block;
  width: ${props => (props.width ? `${props.width}px` : '100px')};
  height: ${props => (props.height ? `${props.height}px` : '14px')};
  background-color: #cccccc;
  border-radius: 3px;
`;

export const Stars = styled.div`
  font-size: 24px;
  line-height: 24px;
  color: #ffc107;
`;

export const FloatingActionButton = styled.div`
  width: 5rem;
  height: 5rem;
  background-color: #ffc107;
  color: white;
  cursor: pointer;
  border-radius: 3rem;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: 10px;
  margin-right: 16px;
  padding: 1rem;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 85%;
  right: 15px;
  z-index: 1000;

  :hover {
    background-color: #ff9807;
  }

  img {
    width: 3rem;
    height: 3rem;
    margin: auto;
    display: block;
  }
`;

export const TabItems = styled(Tabs)`
  ${props =>
    props.fillwithitemcount &&
    `
      > ul > li {
        width: calc(100% / ${props.fillwithitemcount});
      }

      > ul > li > a {
        text-align: center;
        margin-right: 0px;
        background-color: #eee;
        border: 1px solid #ddd;
      }

      > ul > li.active > a {
        background-color: #fff;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
      }
    `}
`;
