import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ProfileImg, Blank } from '../common/StyledComponents';
import { BeerStoryCore } from '../../include/BeerStoryCore';
import UserStore from '../../stores/UserStore';

const Wrapper = styled.div`
  display: flex;
  margin: 7px 0;
`;

const CommentUser = styled.div`
  display: flex;
  color: black;
  font-size: 1em;
`;
const CommentContents = styled.div`
  display: block;
  margin-left: 5px;
`;

class CommentItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: { ...props.comment, displayName: '', photoURL: '' },
    };

    BeerStoryCore.userFromSession(this.props.comment).then(user =>
      this.setUserInfo(user)
    );
  }

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  setUserInfo = user => {
    if (this.mounted) {
      this.setState({
        comment: {
          ...this.state.comment,
          displayName: user.displayName,
          photoURL: user.photoURL,
        },
      });
    }
  };

  render() {
    const { comment } = this.state;
    const uid = UserStore.getCurrentUid();

    return (
      <Wrapper>
        <ProfileImg src={comment.photoURL} />
        <CommentContents>
          <div>
            <CommentUser>
              {comment.displayName ? (
                <Link
                  to={`/users/${comment.uid}`}
                  onClick={() => window.scrollTo(0, 0)}>
                  <b>{comment.displayName}</b>
                </Link>
              ) : (
                <Blank width={80} />
              )}
              <span style={{ marginLeft: '5px' }} className="bs_text_sub_gray">
                {BeerStoryCore.diffDate(comment.creationTime)}
              </span>
              {uid === comment.uid && (
                <i
                  onClick={() => this.confirmDeleteComment(comment.id)}
                  style={{ marginLeft: '10px' }}
                  className="far fa-trash-alt bs_text_sub_gray bs_clickable"
                />
              )}
            </CommentUser>
          </div>
          <div className="bs_text_sub">{comment.comment}</div>
        </CommentContents>
      </Wrapper>
    );
  }
}

export default CommentItem;
