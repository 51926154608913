import React from 'react';
import { BSImage } from '../../res/BSImage';

export default class BSFooter extends React.Component {
  render() {
    return (
      <div
        style={{
          color: '#505050',
          fontSize: '.8em',
          marginTop: '5px',
          marginLeft: '5px',
        }}>
        <div style={{ display: 'flex' }}>
          <img
            style={{
              width: '32px',
              height: '32px',
              marginTop: '3px',
              marginRight: '5px',
            }}
            alt=""
            src={BSImage['ic_bs']}
          />
          <div style={{ display: 'block' }}>
            <div style={{ fontSize: '1.2em' }}>
              <b>BeerStory</b>
            </div>
            <div>
              <b>version Cass</b> | v1.2.4 | 2019.06.03
            </div>
          </div>
        </div>
        <div>© Copyright 2018. DoodleFactory All rights reserved.</div>
      </div>
    );
  }
}
