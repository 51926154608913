import { observable, action } from 'mobx';
import Store from '../Store';

export default class MyBeerModalStore extends Store {
  @observable myBeers = null;
  @observable user = null;

  @action openMyBeerModal = (myBeers, user) => {
    this.myBeers = myBeers;
    this.user = user;
  };
  @action closeModal = () => {
    this.myBeers = null;
    this.user = null;
  };
}
