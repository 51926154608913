import React, { Component } from 'react';
import styled from 'styled-components';
import $ from 'jquery';
import './ImageUploaderPreview.css';

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;
const WrapperInner = styled.div`
  width: 100%;
  display: flex;
`;
const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  margin: 10px;
  padding: 10px;
  background: #edf2f6;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
`;
const ImageButton = styled.div`
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  right: 2px;
  width: 20px;
  height: 20px;
  color: white;
  font-size: 13px;
  line-height: 20px;
  background-color: #edb202;
  top: 2px;
`;

class ImageUploaderPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(null);
  }

  setImages = imgs => {
    this.setState({ images: imgs });
  };
  getImages = () => {
    const { images } = this.state;
    if (!images) return [];
    let imgs = images.map((img, idx) => {
      const imgComponent = $(`#image-upload-preview-${idx} > img`);
      let rotate = 0;
      if (imgComponent && imgComponent.length > 0) {
        rotate = parseInt(imgComponent.attr('cw') || 0);
      }

      if (img && img.url && img.url.constructor === String) {
        return {
          name: img.name,
          url: img.url,
        };
      } else {
        return {
          file: img,
          name: img.name,
          rotate: rotate,
        };
      }
    });
    return imgs;
  };

  loadImageFile = (img, idx) => {
    var reader = new FileReader();
    reader.onload = function() {
      $(`#image-upload-preview-${idx} > img`).attr('src', reader.result);
    };
    reader.readAsDataURL(img);
    return (
      <ImageWrapper key={idx} id={`image-upload-preview-${idx}`}>
        <img className="image-upload-preview" alt="preview" />
        <ImageButton onClick={() => this.removeImageByIdx(idx)}>
          <i className="fas fa-times" />
        </ImageButton>
        <ImageButton
          style={{ top: '75px' }}
          onClick={() => this.rotateImageByIdx(idx)}>
          <i className="fas fa-undo fa-flip-horizontal" />
        </ImageButton>
      </ImageWrapper>
    );
  };

  loadImageSrc = (url, idx, rotate) => {
    return (
      <ImageWrapper key={idx} id={`image-upload-preview-${idx}`}>
        <img
          src={url}
          className="image-upload-preview"
          alt="preview"
          cw={`${rotate}`}
        />
        <ImageButton onClick={() => this.removeImageByIdx(idx)}>
          <i className="fas fa-times" />
        </ImageButton>
      </ImageWrapper>
    );
  };

  renderImages = images => {
    return images.map((img, idx) => {
      if (img instanceof File) {
        return this.loadImageFile(img, idx);
      } else if (img && img.url && img.url.constructor === String) {
        const parse = img.name.split('.')[0].split('_');
        let rotate = parse[parse.length - 1];
        if (isNaN(rotate) || rotate % 90 !== 0) rotate = 0;
        return this.loadImageSrc(img.url, idx, rotate);
      } else return '';
    });
  };
  removeImageByIdx = idx => {
    const { images } = this.state;
    if (!images || idx < images.length) {
      images.splice(idx, 1);
      this.setState({ images: images });
    }
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(images);
    }
  };
  rotateImageByIdx = idx => {
    const img = $(`#image-upload-preview-${idx} > img`);
    if (img && img.length > 0) {
      let rotate = (parseInt(img.attr('cw') || 0) + 90) % 360;
      img.attr('cw', rotate);
    }
  };

  render() {
    const { images } = this.state;
    return (
      <Wrapper>
        <WrapperInner>{images && this.renderImages(images)}</WrapperInner>
      </Wrapper>
    );
  }
}

export default ImageUploaderPreview;
