import { observable, action } from 'mobx';
import Store from '../Store';

export default class LoadingModalStore extends Store {
  @observable isShowing = false;

  @action show = () => {
    this.isShowing = true;
  };
  @action hide = () => {
    this.isShowing = false;
  };
}
