import { observable, action } from 'mobx';
import Store from '../Store';

export default class AddBeerModalStore extends Store {
  constructor(root) {
    super(root);
    this.beer.beers = JSON.parse(sessionStorage.getItem('bs_beers'));
  }

  @observable isShowing = false;

  @observable beer = {};
  @observable prevNameEn = '';
  @observable prevNameKr = '';
  @observable prevBreweryName = '';

  @action openAddModal = beer => {
    this.beer = beer;
    this.prevNameEn = beer.nameEn ? beer.nameEn : '';
    this.prevNameKr = beer.nameKr ? beer.nameKr : '';
    this.prevBreweryName = beer.breweryName ? beer.breweryName : '';
    this.isShowing = true;
  };
  @action closeModal = () => {
    this.beer = {};
    this.prevNameEn = '';
    this.prevNameKr = '';
    this.prevBreweryName = '';
    this.isShowing = false;
  };
}
