import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import BSLoading from '../common/BSLoading';

@inject(({ loadingModal }) => ({
  isShowing: loadingModal.isShowing,
}))
class LoadingModal extends Component {
  render() {
    return (
      <Modal show={this.props.isShowing} dialogClassName="bs_loading_modal">
        <Modal.Body>
          <BSLoading />
        </Modal.Body>
      </Modal>
    );
  }
}

export default LoadingModal;
