import React, { Component } from 'react';
import PostList from '../post/PostList';

class PostIndexTab extends Component {
  render() {
    return <PostList />;
  }
}

export default PostIndexTab;
