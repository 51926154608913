import React, { Component } from 'react';
import styled from 'styled-components';
import classNames from 'classnames/bind';
import firebase from '../../include/firebase_core';
import { BeerStoryCore } from '../../include/BeerStoryCore';
import CommentLayout from './CommentLayout';
import { inject, observer } from 'mobx-react';
import UserStore from '../../stores/UserStore';

const SummaryText = styled.div`
  display: flex;
  margin-top: 5px;
  padding-left: 10px;
  margin-bottom: 5px;
  > div {
    margin-right: 10px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  border-top: 1px solid #c0c0c0;
  font-size: 0.9em;
  > div {
    width: 50%;
    text-align: center;
    padding: 7px;
    background: none;
    cursor: pointer;
  }
  > div:hover {
    background: #dfdfdf;
  }
  > div:first-child {
    border-right: 1px solid #c0c0c0;
    border-bottom-left-radius: 0.3em;
  }
  > div:last-child {
    border-bottom-right-radius: 0.3em;
  }
`;

@inject(({ user, likeModal }) => ({
  user: user.user,
  openLikeModal: likeModal.openLikeModal,
}))
@observer
class PostFooter extends Component {
  constructor(props) {
    super(props);

    const { post } = props;
    const likeCount = post && post.likeCount ? post.likeCount : 0;
    const commentCount = post && post.commentCount ? post.commentCount : 0;
    this.state = {
      likeCount: likeCount,
      commentCount: commentCount,
      showCommentLayout: false,
    };
  }

  getNumberFormat(count) {
    if (!count || count < 1) {
      return;
    }
    let addK = '';
    if (count > 1000) {
      count = (count / 1000).toFixed(1);
      addK = 'k';
    }
    return `${count.toLocaleString()}${addK}`;
  }
  likeSummary(count) {
    count = this.getNumberFormat(count);
    if (count) {
      return (
        <div
          className="bs_clickable"
          onClick={() =>
            this.props.openLikeModal(this.props.post, this.state.likeCount)
          }>
          <b>좋아요 {count}개</b>
        </div>
      );
    }
  }
  updateCommentSummary(count) {
    this.setState({
      commentCount: count,
    });
  }
  commentSummary(count) {
    count = this.getNumberFormat(count);
    if (count) {
      return (
        <div
          className="bs_clickable"
          onClick={this.toggleCommentLayout.bind(this)}>
          댓글 {count}개 {this.state.showCommentLayout ? '숨기기' : '보기'}
        </div>
      );
    }
  }

  toggleCommentLayout() {
    this.commentLayout.toggle(!this.state.showCommentLayout);
    this.setState({ showCommentLayout: !this.state.showCommentLayout });
  }

  toggleLike() {
    const { user, post } = this.props;
    const { isLike } = this.state;

    if (!UserStore.check_user_and_alert(false) || !user) return;
    if (isLike === undefined) return;

    var likeRef = firebase
      .firestore()
      .collection('comments.post')
      .doc(post.id)
      .collection('likes')
      .doc(user.uid);

    const { likeCount } = this.state;
    if (isLike) {
      likeRef
        .delete()
        .then(data => {
          if (this.mounted) {
            this.setState({
              isLike: false,
              likeCount: likeCount > 0 ? likeCount - 1 : 0,
            });
          }
        })
        .catch(e => {
          BeerStoryCore.alert_server_connection(e);
        });
    } else {
      const like = {
        uid: user.uid,
        displayName: user.displayName,
        photoURL: user.photoURL,
        creationTime: new Date().getTime(),
      };
      likeRef
        .set(like)
        .then(data => {
          if (this.mounted) {
            this.setState({
              isLike: true,
              likeCount: likeCount + 1,
            });
          }
        })
        .catch(e => {
          BeerStoryCore.alert_server_connection(e);
        });
    }
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchIsLike();
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  fetchIsLike() {
    const uid = UserStore.getCurrentUid();
    const { post } = this.props;
    if (!uid || !post || !post.id) return;

    var likeRef = firebase
      .firestore()
      .collection('comments.post')
      .doc(post.id)
      .collection('likes')
      .doc(uid);
    likeRef
      .get()
      .then(data => {
        if (this.mounted) {
          this.setState({ isLike: data.exists });
        }
      })
      .catch(e => {
        BeerStoryCore.alert_server_connection(e);
      });
  }

  render() {
    const { post } = this.props;
    const { likeCount, commentCount } = this.state;

    return (
      <div>
        <SummaryText className="bs_text_sub_gray">
          {this.likeSummary(likeCount)}
          {this.commentSummary(commentCount)}
        </SummaryText>
        <CommentLayout
          post={post}
          onRef={ref => (this.commentLayout = ref)}
          updateCommentSummary={this.updateCommentSummary.bind(this)}
        />
        <ButtonWrapper className=" bs_text_gray" style={{ display: 'flex' }}>
          <div
            className={classNames({ bs_text_primary: this.state.isLike })}
            onClick={this.toggleLike.bind(this)}>
            <i className="fas fa-heart" /> 좋아요
          </div>
          <div onClick={this.toggleCommentLayout.bind(this)}>
            <i className="far fa-comment-alt" /> 댓글{' '}
            {this.state.showCommentLayout ? '숨기기' : '달기'}
          </div>
        </ButtonWrapper>
      </div>
    );
  }
}

export default PostFooter;
