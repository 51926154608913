import React, { Component } from 'react';
import styled from 'styled-components';
import $ from 'jquery';
import _ from 'lodash';
import classNames from 'classnames/bind';
import { confirmAlert } from 'react-confirm-alert';
import { Button } from 'react-bootstrap';
import { Creatable } from 'react-select';
import Rating from 'react-rating';
import Tooltip from 'react-tooltip-lite';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import firebase from '../../include/firebase_core';
import { BeerStoryCore } from '../../include/BeerStoryCore';
import BSLoading from '../common/BSLoading';
import './postEdit.css';
import { Stars, ColumnContent } from '../common/StyledComponents';
import { inject } from 'mobx-react';
import UserStore from '../../stores/UserStore';
import ImagePicker from '../common/ImagePicker';

const PostForm = styled.div`
  padding: 20px;
  margin: 0 2%;
  background: white;
  border: 2px solid rgba(34, 36, 38, 0.15);
  border-top: none;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  box-shadow: 0.5px 0.5px #a0a0a0;

  a {
    color: inherit;
  }
`;
const Divider = styled.div`
  display: flex;
  border-top: 1px solid #d6d6d6;
  margin-top: 8px;
  margin-bottom: 3px;
  padding: 5px 0px;
  font-size: 0.75em;
  color: gray;
`;
const NoteTextarea = styled.textarea`
  flex: 1;
  min-height: 150px;
  padding: 5px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
`;

const NOTE_FORM = '* 누구랑?';
@inject(({ user, beers, addBeerModal, posts, loadingModal }) => ({
  user: user.user,
  beers: beers.beers,
  updatedBeer: beers.updatedBeer,
  beerListUpdated: beers.beerListUpdated,
  loadBeerList: beers.loadBeerList,
  openAddModal: addBeerModal.openAddModal,
  fetchPost: posts.fetchPost,
  showProgress: loadingModal.show,
  hideProgress: loadingModal.hide,
  isOnProgress: loadingModal.isShowing,
}))
class PostEdit extends Component {
  constructor(props) {
    super(props);

    const defaultPost = {
      notes: '',
      prevNote: '',
      price: '',
      servingTypeId: 0,
      beerId: null,
      // breweryId: null,
      rating: -1,
      date: moment(),
      flavors: BeerStoryCore.getDefaultFlavors(2.5),
    };

    this.state = {
      beer: null,
      post: defaultPost,
      useForm: false,
    };

    const postId = this.props.match.params.id;
    if (postId) {
      this.props.showProgress();
      props.fetchPost(postId, post => {
        if (this.mounted) {
          this.setState({
            post: this.parseForEdit(post, postId),
            beer: this.props.beers[post.beerId],
          });
          this.loadImageUrls(post, postId);
          this.props.hideProgress();
        }
      });
    }
  }

  loadImageUrls = (post, postId) => {
    const { images } = post;
    if (images && images.length > 0) {
      var ref = firebase.storage().ref(`posts/${postId}`);
      Promise.all(images.map(item => ref.child(item).getDownloadURL())).then(
        downloadURLs => {
          if (this.imagePicker) {
            let imgObjs = downloadURLs.map((url, idx) => {
              return {
                name: images[idx],
                url: url,
              };
            });
            this.imagePicker.setImages(imgObjs);
          }
        }
      );
    }
  };

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.props.beerListUpdated(null);
    this.mounted = false;
  }

  onBeerSelected(beer) {
    var beerId = null;
    if (!!beer) {
      beerId = beer.id;
    }
    this.setState({
      beer: beer,
      post: {
        ...this.state.post,
        beerId: beerId,
      },
    });
  }

  onBeerCreate(beerName) {
    var check = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    var hasKorean = false;
    if (check.test(beerName)) hasKorean = true;
    const nameEn = !hasKorean ? beerName : '';
    const nameKr = hasKorean ? beerName : '';
    this.props.openAddModal({ nameEn: nameEn, nameKr: nameKr });
  }

  renderBeerDetail() {
    const { beer } = this.state;

    if (!!beer) {
      var style = null;
      if (!!beer.styleId && !!BeerStoryCore.styles[beer.styleId]) {
        style = BeerStoryCore.styles[beer.styleId];
      }

      var detail = '';
      detail += beer.breweryName ? `Brewery : ${beer.breweryName}\n` : '';
      detail += style && style.name !== '-' ? `Style : ${style.name}\n` : '';
      detail += beer.abv ? `ABV : ${beer.abv}%\n` : '';
      detail += beer.ibu ? `IBU : ${beer.ibu}\n` : '';

      return (
        <div className="bs_post_edit_row">
          {detail.split('\n').map((row, i) => {
            return (
              <p style={{ marginBottom: 0 }} key={i}>
                {row}
              </p>
            );
          })}
          <Button
            className="bs_post_edit_row_btn_edit"
            onClick={() => {
              this.props.openAddModal(beer);
            }}
            style={{ fontSize: '.75em', padding: '2px' }}>
            Edit
          </Button>
        </div>
      );
    }
  }

  onPriceInputChanged(target) {
    var price =
      target.value.length === 0
        ? ''
        : parseFloat(target.value.replace(/,/g, ''));
    if (isNaN(price)) return;
    this.setState({
      post: { ...this.state.post, price: price.toLocaleString() },
    });
  }

  renderFlavorRating() {
    const flavors = BeerStoryCore.flavors;
    return _.map(flavors, flavor => {
      if (flavor.id % 2 === 0) {
        return (
          <div
            className="bs_post_edit_row bs_post_edit_row_indented bs_post_edit_row_mobile_sm"
            key={flavor.id}>
            {this.renderFlavorRatingItemRow(flavors, flavor.id)}
            {this.renderFlavorRatingItemRow(flavors, flavor.id + 1)}
          </div>
        );
      }
    });
  }

  renderFlavorRatingItemRow(flavors, id) {
    const flavor = flavors[id];
    return (
      <div className="bs_post_edit_col-2">
        {flavor ? (
          <div className="bs_post_edit_row">
            <div
              className={classNames(
                'bs_post_edit_row_label',
                'bs_post_edit_row_label_flavor',
                { 'bs_post_edit_row_label_col-2': id % 2 !== 0 }
              )}>
              {flavor.name}
              <Tooltip
                className="bs_text_sub"
                styles={{ display: 'inline-flex' }}
                content={flavor.desc}
                arrow={true}
                padding="3px"
                background="#e0e0e0"
                color="#3d3d3d"
                direction="up-start"
                eventOn="onClick">
                <i className="fas fa-info-circle" />
              </Tooltip>
            </div>
            <div className="bs_post_edit_rating_label bs_text_sub_gray">
              <div className="bs_post_edit_row">
                0 {this.renderFlavorRatingItem(flavor.name)} 5
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  onRatingChanged(rate) {
    this.setState({ post: { ...this.state.post, rating: rate } });
  }

  renderFlavorRatingItem(name) {
    return (
      <Rating
        id={`bs_rate_flavor_${name}`}
        className={'bs_rate_flavor'}
        fractions={2}
        emptySymbol={
          <i className="far fa-circle" style={{ margin: '0 2px' }} />
        }
        fullSymbol={<i className="fas fa-circle" style={{ margin: '0 2px' }} />}
        initialRating={this.state.post.flavors[name]}
        onChange={rate => this.onFlavorRatingChanged(name, rate)}
      />
    );
  }

  onFlavorRatingChanged(name, rate) {
    var flavors = this.state.post.flavors;
    flavors[name] = rate;
    this.setState({ post: { ...this.state.post, flavors: flavors } });
  }

  onDateChanged(date) {
    this.setState({ post: { ...this.state.post, date: date } });
  }

  generatePost() {
    const { post } = this.state;
    if (!post.beerId) {
      $.notify({ message: '맥주를 선택해 주세요.' }, { type: 'warning' });
      return null;
    }
    if (post.rating < 0) {
      $.notify({ message: '평점을 입력해 주세요.' }, { type: 'warning' });
      return null;
    }
    const creationTime = post.creationTime
      ? post.creationTime
      : new Date().getTime();
    var editing = {
      ...post,
      creationTime: creationTime,
      date: post.date.valueOf(),
      price: post.price.replace(/,/g, '') * 1,
      notes: post.notes.replace('\r\n', '<br>'),
    };
    delete editing.prevNote;
    return editing;
  }
  parseForEdit = (post, postId) => {
    return {
      ...post,
      id: postId,
      date: moment(post.date),
      price: post.price.toLocaleString(),
      prevNote: post.notes,
    };
  };

  updateBeerRatings(post) {
    const { beer } = this.state;

    var prevPostCount = beer.postCount ? beer.postCount : 0;
    const postCount = prevPostCount + 1;
    if (prevPostCount === 0) {
      prevPostCount++;
    }

    var rating = beer.rating ? beer.rating : 0;
    rating = (rating * prevPostCount + post.rating) / postCount;

    var flavors = beer.flavors ? beer.flavors : BeerStoryCore.defaultFlavors;
    for (var k in flavors) {
      if (post.flavors.hasOwnProperty(k)) {
        flavors[k] = (flavors[k] * prevPostCount + post.flavors[k]) / postCount;
      }
    }

    var beerRef = firebase
      .firestore()
      .collection('beers')
      .doc(beer.id);
    return beerRef.update({
      rating: rating,
      flavors: flavors,
      postCount: postCount,
    });
  }

  updatePost = (post, imageUploaded) => {
    var postRef = firebase.firestore().collection('posts');
    const postId = post.id;
    if (postId) {
      delete post.id;
      postRef = postRef.doc(postId).update(post);
    } else {
      postRef = postRef.add(post);
    }
    postRef
      .then(ref => {
        let images = this.imagePicker.getImages();
        if (!imageUploaded && images.length > 0) {
          post.id = ref ? ref.id : postId;
          this.uploadImagesAndUpdatePost(post, images);
        } else {
          this.props.hideProgress();
          this.props.history.replace('/');
        }
      })
      .catch(e => {
        BeerStoryCore.alert_server_connection(e);
        this.props.hideProgress();
      });
  };
  uploadImagesAndUpdatePost(post, images) {
    const time = new Date().getTime();
    const files = [];

    Promise.all(
      images.map((image, idx) => {
        if (image.url) {
          files[idx] = image.name;
          return Promise.resolve();
        } else if (image.file) {
          let rotate = image.rotate;
          if (!rotate) {
            rotate = 0;
          }
          const ext = image.name.split('.').pop();
          const fileName = `${time}_${idx}_${rotate}.${ext}`;
          files[idx] = fileName;
          return firebase
            .storage()
            .ref()
            .child(`posts/${post.id}/${fileName}`)
            .put(image.file);
        } else return Promise.resolve();
      })
    )
      .then(_ => {
        post.images = files;
        this.updatePost(post, true);
      })
      .catch(e => {
        BeerStoryCore.alert_server_connection(e);
        this.props.hideProgress();
      });
  }

  onSubmitClicked() {
    var post = this.generatePost();
    var { user } = this.props;
    if (!!post) {
      if (!!user) {
        this.props.showProgress();
        post = {
          ...post,
          uid: user.uid,
          userDisplayName: user.displayName,
          userPhotoUrl: user.photoURL,
        };
        this.updateBeerRatings(post)
          .then(() => this.updatePost(post))
          .catch(e => {
            BeerStoryCore.alert_server_connection(e);
            this.props.hideProgress();
          });
      } else {
        BeerStoryCore.alert_server_connection();
      }
    }
  }

  onCancelClicked() {
    confirmAlert({
      message: '작성 중이던 내용이 사라집니다. 작성을 취소하시겠습니까?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.history.push('/'),
        },
        { label: 'No' },
      ],
    });
  }

  renderSubmitLayout() {
    return (
      <div className="bs_post_edit_row">
        <Button
          type="button"
          bsStyle="info"
          className="bs_post_edit_btn bs_post_edit_col-2"
          onClick={this.onCancelClicked.bind(this)}>
          Cancel
        </Button>
        <Button
          type="button"
          bsStyle="primary"
          className="bs_post_edit_btn bs_post_edit_col-2"
          disabled={this.props.isOnProgress}
          onClick={this.onSubmitClicked.bind(this)}>
          Submit
        </Button>
      </div>
    );
  }

  render() {
    if (!UserStore.check_user_and_alert(true)) {
      return <BSLoading />;
    }
    const { post } = this.state;
    if (post.uid && this.props.user && post.uid !== this.props.user.uid) {
      $.notify(
        {
          title: 'Warning',
          message: '다른 사람이 작성한 포스트는 수정할 수 없습니다.',
          url: '/',
          target: '_self',
        },
        { type: 'warning', delay: 5000 }
      );
      return <div />;
    }

    if (this.props.beers === null) {
      this.props.loadBeerList();
      return <div />;
    }

    if (
      !!this.props.updatedBeer &&
      (!this.state.beer ||
        (!!this.state.beer &&
          this.props.updatedBeer !== this.state.beer &&
          this.props.updatedBeer.id === this.state.beer.id))
    ) {
      this.onBeerSelected(this.props.updatedBeer);
      return <div />;
    }

    return (
      <ColumnContent>
        <PostForm>
          <div className="bs_post_edit_row">
            <div className="bs_post_edit_row_label">
              <b>Beer</b>
            </div>
            <div className="bs_post_edit_row_fill_width">
              <Creatable
                className="bs_post_edit_form_selectable"
                placeholder="Select beer..."
                isClearable
                value={this.state.beer}
                onChange={this.onBeerSelected.bind(this)}
                onCreateOption={this.onBeerCreate.bind(this)}
                options={Object.values(this.props.beers)}
              />
            </div>
          </div>

          <div
            className={classNames(
              'bs_post_edit_row bs_post_edit_info_sub bs_text_sub_gray',
              { bs_display_none: !this.state.beer }
            )}>
            {this.renderBeerDetail()}
          </div>

          <div className="bs_post_edit_row bs_post_edit_row_mobile">
            <div className="bs_post_edit_col-2">
              <div className="bs_post_edit_row">
                <div className="bs_post_edit_row_label">
                  <b>Rating</b>
                </div>
                <Stars className="bs_post_edit_row_fill_width">
                  <Rating
                    emptySymbol={<i className="far fa-star" />}
                    fullSymbol={<i className="fas fa-star" />}
                    fractions={2}
                    initialRating={post.rating}
                    onChange={rate => this.onRatingChanged(rate)}
                  />
                </Stars>
              </div>
            </div>

            <div className="bs_post_edit_col-2">
              <div className="bs_post_edit_row">
                <div className="bs_post_edit_row_label bs_post_edit_row_label_col-2">
                  <b>Date</b>
                </div>
                <div className="bs_post_edit_row_fill_width">
                  <DatePicker
                    selected={post.date}
                    onChange={date =>
                      this.setState({
                        post: { ...this.state.post, date: date },
                      })
                    }
                    dateFormat="YYYY/MM/DD"
                  />
                </div>
              </div>
            </div>
          </div>

          <Divider>(선택입력사항)</Divider>
          <ImagePicker onRef={ref => (this.imagePicker = ref)} />
          <div className="bs_post_edit_row bs_post_edit_row_mobile">
            <div className="bs_post_edit_col-2">
              <div className="bs_post_edit_row">
                <div className="bs_post_edit_row_label">
                  <b>Price</b>
                </div>
                <div className="bs_post_edit_row_fill_width">
                  <input
                    className="bs_post_edit_form_input"
                    type="text"
                    min="0"
                    value={post.price !== '0' ? post.price : ''}
                    onChange={e => {
                      this.onPriceInputChanged(e.target);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="bs_post_edit_col-2">
              <div className="bs_post_edit_row">
                <div className="bs_post_edit_row_label bs_post_edit_row_label_col-2">
                  <b>Serving</b>
                </div>
                <div className="bs_post_edit_row_fill_width">
                  <select
                    className="form-control bs_post_edit_form_input"
                    value={post.servingTypeId}
                    onChange={e => {
                      this.setState({
                        post: { ...post, servingTypeId: e.target.value },
                      });
                      this.imagePicker.getImages();
                    }}
                    id="bs_post_edit_form_input_serving"
                    style={{ height: '2em', padding: '0px' }}>
                    {Object.keys(BeerStoryCore.servingType).map(k => (
                      <option
                        key={BeerStoryCore.servingType[k].id}
                        value={BeerStoryCore.servingType[k].id}>
                        {BeerStoryCore.servingType[k].name} (
                        {BeerStoryCore.servingType[k].desc})
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="bs_post_edit_row_label bs_post_edit_row_label_row">
              <b>Flavor</b>
            </div>
            {this.renderFlavorRating()}
          </div>

          <div>
            <div className="bs_post_edit_row">
              <div className="bs_post_edit_row_label bs_post_edit_row_label_row">
                <div>
                  <b>Note</b>
                </div>
                <div className="bs_display_none">
                  <input
                    type="checkbox"
                    checked={this.state.useForm}
                    onChange={e => {
                      if (e.target.checked) {
                        this.setState({
                          useForm: e.target.checked,
                          post: { ...post, notes: NOTE_FORM },
                        });
                      } else {
                        this.setState({
                          useForm: e.target.checked,
                          post: {
                            ...post,
                            notes: post.prevNote,
                          },
                        });
                      }
                    }}
                  />
                  <label>Use form</label>
                </div>
              </div>
            </div>

            <div className="bs_post_edit_row">
              <NoteTextarea
                type="text"
                className="bs_post_edit_note"
                aria-invalid="false"
                placeholder="시음 후기를 작성해 주세요."
                value={post.notes}
                onChange={e =>
                  this.setState({
                    useForm: false,
                    post: {
                      ...post,
                      notes: e.target.value,
                      prevNote: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>

          {this.renderSubmitLayout()}
        </PostForm>
      </ColumnContent>
    );
  }
}

export default PostEdit;

/*
-----------------------------------------
| BEER ===========================▼      | -> popup(add from last item of searched list)
|   Style =====  ABV =====  IBU =====  * | -> popup(edit)
| Brewery ========================▼      | -> popup(add from last item of searched list)
|   country (city, state) url          * | -> popup(edit)
|                                        |
| price =====        ServingType =====▼  |
| RATING ★★★★★     date  =====         |
| Flavor                                 |
|   flavor - ===== + flavor - ===== +    |
|   flavor - ===== + flavor - ===== +    |
|   flavor - ===== + flavor - ===== +    |
|   flavor - ===== + flavor - ===== +    |
| Note                                   |
|   =================================    |
|   =================================    |
|   =================================    |
|   =================================    |
|   =================================    |
|                                        |
-----------------------------------------


*/
