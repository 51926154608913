import React, { Component } from 'react';
import $ from 'jquery';
import { Modal, Button } from 'react-bootstrap';
import { Creatable } from 'react-select';
import Tooltip from 'react-tooltip-lite';
import firebase from '../../include/firebase_core';
import styled from 'styled-components';
import { BeerStoryCore } from '../../include/BeerStoryCore';
import './addBeerModal.css';
import { inject, observer } from 'mobx-react';

const BreweryCreatable = styled(Creatable)`
  width: 100%;
  ${props =>
    props.isDisabled &&
    `
      div {
        background-color: #e3e3e3;
      }
    `}
`;

@inject(({ beers, addBeerModal }) => ({
  getBreweryList: beers.getBreweryList,
  beerListUpdated: beers.beerListUpdated,
  isShowing: addBeerModal.isShowing,
  beer: addBeerModal.beer,
  prevNameEn: addBeerModal.prevNameEn,
  prevNameKr: addBeerModal.prevNameKr,
  prevBreweryName: addBeerModal.prevBreweryName,
  openAddModal: addBeerModal.openAddModal,
  closeModal: addBeerModal.closeModal,
}))
@observer
class AddBeerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beer: {},
      breweries: [],
    };
  }

  closeModal() {
    this.props.closeModal();
  }

  onEnterModal() {
    const { beer } = this.props;
    this.setState({
      beer: { ...beer },
      breweries: this.props.getBreweryList(),
      breweryName: this.props.prevBreweryName,
    });
  }

  generateBeer() {
    var beer = {
      id: this.props.beer.id,
      nameEn: $('#bs_modal_add_beer_name_en')[0].value,
      nameKr: $('#bs_modal_add_beer_name_kr')[0].value,
      breweryName: this.state.breweryName,
      styleId: $('#bs_modal_add_beer_style')[0].value,
      abv: $('#bs_modal_add_beer_abv')[0].value,
      ibu: $('#bs_modal_add_beer_ibu')[0].value,
      creationTime: this.props.beer.creationTime
        ? this.props.beer.creationTime
        : new Date().getTime(),
      rating: this.props.beer.rating ? this.props.beer.rating : 0,
      flavors: this.props.beer.flavors
        ? this.props.beer.flavors
        : BeerStoryCore.defaultFlavors,
      postCount: this.props.beer.postCount ? this.props.beer.postCount : 0,
    };
    if (beer.abv.length > 0) {
      beer.abv = parseFloat(beer.abv).toFixed(1);
    }

    if (beer.nameEn.length <= 0 && beer.nameKr.length <= 0) {
      $.notify({ message: '맥주 이름을 입력해 주세요.' }, { type: 'warning' });
      return null;
    }
    return beer;
  }

  submitBeer(beer) {
    var beerRef = firebase.firestore().collection('beers');
    if (!!beer.id) {
      return beerRef.doc(beer.id).update(beer);
    } else {
      delete beer.id;
      return beerRef.add(beer);
    }
  }

  addSubmit() {
    var beer = this.generateBeer();
    if (beer === null) return;
    var beerRequest = this.submitBeer(beer);
    if (beerRequest != null) {
      beerRequest
        .then(data => {
          if (!!data) {
            beer.id = data.id;
          }

          if (beer.nameEn.length <= 0 || beer.nameKr.length <= 0) {
            if (beer.nameEn.length > 0) {
              beer.label = beer.nameEn;
              beer.value = beer.nameEn;
            }
            if (beer.nameKr.length > 0) {
              beer.label = beer.nameKr;
              beer.value = beer.nameKr;
            }
          } else {
            beer.label = `${beer.nameEn}-${beer.nameKr}`;
            beer.value = `${beer.nameEn}-${beer.nameKr}`;
          }

          this.props.beerListUpdated(beer);

          this.closeModal();
        })
        .catch(e => BeerStoryCore.alert_server_connection(e));
    }
  }

  render() {
    const { beer } = this.state;

    return (
      <Modal
        show={this.props.isShowing}
        onHide={this.closeModal.bind(this)}
        onEnter={this.onEnterModal.bind(this)}
        dialogClassName="bs_modal_info">
        <Modal.Header closeButton>
          <b>{beer.id ? '맥주 수정' : '맥주 추가'}</b>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: '0px' }}>
          <div className="bs_modal_add_layout_beer">
            <div
              className="bs_post_edit_divider-top"
              style={{
                borderTop: 'none',
                marginTop: '2px',
                marginBottom: '0px',
              }}>
              (정확한 명칭으로 작성해주세요.)
            </div>

            <div className="bs_modal_edit_row">
              <div className="bs_modal_edit_row_label">
                <b>Name(EN)</b>
              </div>
              <div className="bs_modal_edit_row_fill_width">
                <input
                  className="bs_modal_edit_form_input"
                  id="bs_modal_add_beer_name_en"
                  type="text"
                  disabled={!!beer.id && this.props.prevNameEn.length > 0}
                  onChange={e =>
                    this.setState({ beer: { ...beer, nameEn: e.target.value } })
                  }
                  value={beer ? beer.nameEn : ''}
                />
              </div>
            </div>

            <div className="bs_modal_edit_row">
              <div className="bs_modal_edit_row_label">
                <b>Name(KR)</b>
              </div>
              <div className="bs_modal_edit_row_fill_width">
                <input
                  className="bs_modal_edit_form_input"
                  id="bs_modal_add_beer_name_kr"
                  type="text"
                  disabled={!!beer.id && this.props.prevNameKr.length > 0}
                  onChange={e =>
                    this.setState({ beer: { ...beer, nameKr: e.target.value } })
                  }
                  value={beer ? beer.nameKr : ''}
                />
              </div>
            </div>

            <div className="bs_post_edit_divider-top">(선택입력사항)</div>
            <div className="bs_modal_edit_row">
              <div className="bs_modal_edit_row_label">
                <b>Brewery</b>
              </div>
              <div className="bs_modal_edit_row_fill_width">
                <BreweryCreatable
                  className="bs_modal_edit_form_creatable"
                  id="bs_modal_add_beer_brewery"
                  placeholder="Select brewery..."
                  minMenuHeight={100}
                  isLoading={!this.state.breweries}
                  isClearable
                  onChange={v => {
                    this.setState({ breweryName: v ? v.value : '' });
                  }}
                  onCreateOption={v => {
                    let breweries = this.state.breweries;
                    breweries.push({
                      label: v,
                      value: v,
                    });
                    this.setState({
                      breweries: breweries,
                      breweryName: v,
                    });
                  }}
                  isDisabled={this.props.prevBreweryName}
                  value={
                    this.state.breweryName && {
                      value: this.state.breweryName,
                      label: this.state.breweryName,
                    }
                  }
                  options={Object.values(this.state.breweries)}
                />
              </div>
            </div>

            <div className="bs_modal_edit_row">
              <div className="bs_modal_edit_row_label">
                <b>Style</b>
              </div>
              <div className="bs_modal_edit_row_fill_width">
                <select
                  className="form-control bs_modal_edit_form_input"
                  id="bs_modal_add_beer_style"
                  value={beer ? beer.styleId : 0}
                  onChange={e =>
                    this.setState({
                      beer: { ...beer, styleId: e.target.value },
                    })
                  }
                  style={{ height: '2em' }}>
                  {Object.keys(BeerStoryCore.styles).map(k => (
                    <option
                      key={BeerStoryCore.styles[k].id}
                      value={BeerStoryCore.styles[k].id}>
                      {!!BeerStoryCore.styles[k].parent
                        ? ` - ${BeerStoryCore.styles[k].name}`
                        : BeerStoryCore.styles[k].name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="bs_modal_edit_row">
              <div className="bs_modal_edit_row_label">
                <b>ABV</b>
                <Tooltip
                  className="bs_text_sub"
                  styles={{ display: 'inline-flex', margin: '2px' }}
                  content={'알콜 도수(Alcohol By Volume)'}
                  arrow={true}
                  padding="3px"
                  background="#e0e0e0"
                  color="#3d3d3d"
                  direction="up-start"
                  eventOn="onClick">
                  <i className="fas fa-info-circle" />
                </Tooltip>
              </div>
              <div className="bs_modal_edit_row_fill_width">
                <input
                  className="bs_modal_edit_form_input"
                  id="bs_modal_add_beer_abv"
                  type="number"
                  min="0"
                  value={beer ? (beer.abv ? beer.abv : '') : ''}
                  onChange={e =>
                    this.setState({
                      beer: {
                        ...beer,
                        abv: e.target.value > 0 ? e.target.value : '',
                      },
                    })
                  }
                  placeholder="알콜 도수"
                />
              </div>
            </div>

            <div className="bs_modal_edit_row">
              <div className="bs_modal_edit_row_label bs_modal_edit_row_label_col-2">
                <b>IBU</b>
                <Tooltip
                  className="bs_text_sub"
                  styles={{ display: 'inline-flex', margin: '2px' }}
                  content={'맥주의 쓴 정도(International Bitterness Unit)'}
                  arrow={true}
                  padding="3px"
                  background="#e0e0e0"
                  color="#3d3d3d"
                  direction="up-start"
                  eventOn="onClick">
                  <i className="fas fa-info-circle" />
                </Tooltip>
              </div>
              <div className="bs_modal_edit_row_fill_width">
                <input
                  className="bs_modal_edit_form_input"
                  id="bs_modal_add_beer_ibu"
                  type="number"
                  min="0"
                  value={beer ? (beer.ibu ? beer.ibu : '') : ''}
                  onChange={e =>
                    this.setState({ beer: { ...beer, ibu: e.target.value } })
                  }
                  placeholder="맥주의 쓴 정도"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            style={{ width: '100%' }}
            onClick={this.addSubmit.bind(this)}>
            저장
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddBeerModal;
