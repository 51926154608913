import React, { Component } from 'react';
import { ColumnContent } from '../common/StyledComponents';
import { Tab } from 'react-bootstrap';
import { TabItems } from '../common/StyledComponents';
import SearchBar from '../common/SearchBar';
import BeerResultList from '../common/BeerResultList';

class SearchTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKeyword: '',
      searchCategory: 'beer',
    };
  }

  render() {
    return (
      <ColumnContent>
        <SearchBar
          hint="검색어를 입력해 주세요."
          onSearchTermChange={searchKeyword => this.setState({ searchKeyword })}
          value={this.state.searchKeyword}
        />
        {this.state.searchKeyword && (
          <TabItems
            fillwithitemcount={3}
            defaultActiveKey={this.state.searchCategory}
            id="search_tab_searchTab"
            onSelect={searchCategory => this.setState({ searchCategory })}>
            <Tab eventKey="beer" title="Beer" />
            <Tab eventKey="brewery" title="Brewery" />
            {/* <Tab eventKey="user" title="User" disabled /> */}
          </TabItems>
        )}
        <BeerResultList
          style={{ marginTop: '20px' }}
          searchKeyword={this.state.searchKeyword}
          searchCategory={this.state.searchCategory}
        />
      </ColumnContent>
    );
  }
}

export default SearchTab;
