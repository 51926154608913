import { observable, action } from 'mobx';
import Store from './Store';
import firebase from '../include/firebase_core';
import $ from 'jquery';
import { BeerStoryCore } from '../include/BeerStoryCore';

export default class UserStore extends Store {
  @observable user = null;

  constructor(root) {
    super(root);

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if (this.isNewCreationUser(user)) {
          this.createUserToDB(user);
        } else {
          this.loadUserInfo(user.uid, data => {
            this.setUser(data);
          });
        }
      } else {
        this.setUser(null);
      }
    });
  }

  @action
  setUser = user => {
    if (user && user.uid) {
      localStorage.setItem('bs_user_fuid', user.uid);
      this.user = user;
    } else {
      localStorage.removeItem('bs_user_fuid');
    }
  };

  loadUserInfo = (uid, cb = null) => {
    const userRef = firebase.firestore().collection('users');
    userRef
      .doc(uid)
      .get()
      .then(user => {
        if (typeof cb === 'function') {
          cb(user.data() || null);
        }
      })
      .catch(e => {
        if (typeof cb === 'function') {
          cb(null);
        }
      });
  };

  isNewCreationUser = user => {
    if (user && user.metadata) {
      return user.metadata.creationTime === user.metadata.lastSignInTime;
    }
    return false;
  };
  createUserToDB = user => {
    if (!!user) {
      const BSUser = {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
      };

      var db = firebase.firestore();
      var ref = db.collection('users').doc(user.uid);
      ref
        .get()
        .then(doc => {
          if (!doc.exists) {
            ref
              .set(BSUser)
              .then(() => {
                this.setUser(BSUser);
              })
              .catch(e => {
                BeerStoryCore.alert_server_connection(e);
              });
          } else {
            this.setUser(doc.data());
          }
        })
        .catch(e => {
          BeerStoryCore.alert_server_connection(e);
        });
    }
  };

  static getCurrentUid = () => {
    return localStorage.getItem('bs_user_fuid');
  };

  static check_user_and_alert = (redirect = false) => {
    if (UserStore.getCurrentUid() !== null) return true;
    UserStore.alert_need_login(redirect);
    return false;
  };
  static alert_need_login = (redirect = false) => {
    var targetUrl = null;
    if (redirect) {
      targetUrl = `${window.location.origin.toString()}/settings`;
    }
    $.notify(
      {
        title: '로그인이 필요합니다.',
        message: '회원가입/로그인 후 진행해주세요.',
        url: targetUrl,
        target: '_self',
      },
      { type: 'warning', delay: 5000 }
    );
  };
}
