import { observable, action } from 'mobx';
import Store from './Store';
import firebase from '../include/firebase_core';
import _ from 'lodash';

export default class BeersStore extends Store {
  @observable beers = {};
  @observable updatedBeer = null;

  loadBeerList = () => {
    var beerRef = firebase.firestore().collection('beers');
    beerRef.get().then(
      action(payload => {
        if (!payload) return;
        var objs = {};

        payload.docs.forEach(doc => {
          objs[doc.id] = doc.data();
          objs[doc.id].id = doc.id;

          if (doc.data().nameEn.length <= 0 || doc.data().nameKr.length <= 0) {
            if (doc.data().nameEn.length > 0) {
              objs[doc.id].label = doc.data().nameEn;
              objs[doc.id].value = doc.data().nameEn;
            }
            if (doc.data().nameKr.length > 0) {
              objs[doc.id].label = doc.data().nameKr;
              objs[doc.id].value = doc.data().nameKr;
            }
          } else {
            objs[doc.id].label = `${doc.data().nameEn}-${doc.data().nameKr}`;
            objs[doc.id].value = `${doc.data().nameEn}-${doc.data().nameKr}`;
          }
        });

        sessionStorage.setItem('bs_beers', JSON.stringify(objs));
        this.beers = objs;
      })
    );
  };

  @action beerListUpdated = beer => {
    if (beer && beer.id) {
      this.beers[beer.id] = beer;
      this.updatedBeer = beer;
    } else {
      this.updatedBeer = null;
    }
  };

  @action getBreweryList = () => {
    return _.map(_.filter(this.beers, beer => !!beer.breweryName), beer => ({
      label: beer.breweryName,
      value: beer.breweryName,
    }));
  };
}
