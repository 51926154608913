import React, { Component } from 'react';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import firebase from '../../include/firebase_core';
import FlavorChart from '../post/FlavorChart';
import './ImageFlavorSlider.css';
import $ from 'jquery';

const Wrapper = styled.div`
  height: 200px;
  width: 100%;
`;
class ImageFlavorSlider extends Component {
  constructor(props) {
    super(props);
    this.state = { chartMoved: false };

    this.loadImageUrls();
  }

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  loadImageUrls = () => {
    const { images } = this.props.post;
    if (images && images.length > 0) {
      var ref = firebase.storage().ref(`posts/${this.props.post.id}`);
      Promise.all(images.map(item => ref.child(item).getDownloadURL())).then(
        downloadURLs => {
          let urls = downloadURLs.map((url, idx) => {
            const parse = images[idx].split('.')[0].split('_');
            let rotate = parse[parse.length - 1];
            if (isNaN(rotate) || rotate % 90 !== 0) rotate = 0;
            return {
              original: url,
              rotate: parseInt(rotate) % 360,
            };
          });
          if (this.props.post.flavors) {
            urls.push({ original: '', rotate: 0 });
          }
          if (this.mounted) {
            this.setState({ images: urls });
          }
        }
      );
    }
  };

  generateFlavorChart = () => {
    return <FlavorChart post={this.props.post} />;
  };

  moveChartToSlide = () => {
    const { post } = this.props;
    const { images } = this.state;
    var imgs = $(`#bs_images-${post.id} .image-gallery-image img`);
    imgs.map((idx, img) => {
      if (0 < idx && idx < images.length && images[idx]) {
        let rotate = images[idx].rotate;
        if (isNaN(rotate)) rotate = 0;
        $(img).attr('cw', rotate);
        if (rotate % 180 !== 0) {
          setTimeout(function() {
            $(img).css('margin-top', ($(img).width() - $(img).height()) >> 1);
          }, 20);
        }
      }
      return true;
    });
    if (this.state.chartMoved) return;

    var chartElement = $(`#bs_flavors-${post.id}`).detach();
    $(`#bs_images-${post.id} .image-gallery-slide:last-child > div`).append(
      chartElement
    );
    this.setState({ chartMoved: true });
  };

  render() {
    const { post } = this.props;
    const { images } = post;
    if (images && images.length > 0) {
      return (
        <Wrapper
          id={`bs_images-${post.id}`}
          style={{ display: this.state.chartMoved ? 'block' : 'none' }}>
          <ImageGallery
            style={{ width: '100%', height: '200px' }}
            items={this.state.images}
            lazyLoad={true}
            infinite={false}
            showThumbnails={false}
            showFullscreenButton={false}
            useTranslate3D={false}
            showPlayButton={false}
            showBullets={true}
            onImageLoad={() => this.moveChartToSlide()}
          />
        </Wrapper>
      );
    } else {
      return <></>;
    }
  }
}

export default ImageFlavorSlider;
