import React, { Component } from 'react';
import styled from 'styled-components';
import { inject } from 'mobx-react';
import firebase from '../../include/firebase_core';
import { Modal } from 'react-bootstrap';
import BSLoading from '../common/BSLoading';
import UserRow from '../UserRow';
import { BeerStoryCore } from '../../include/BeerStoryCore';

const ModalBody = styled(Modal.Body)`
  padding: 0px;
  max-height: 300px;
  overflow-y: auto;
`;
const MoreLayout = styled.div`
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
`;

@inject(({ likeModal }) => ({
  likeItem: likeModal.likeItem,
  likeCount: likeModal.likeCount,
  closeModal: likeModal.closeModal,
}))
class LikeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onLoading: false,
      likes: [],
    };
  }

  closeModal() {
    this.setState({ likes: [] });
    this.props.closeModal();
  }

  loadLikes(showProgress = true) {
    if (showProgress) {
      this.setState({ onLoading: true });
    }
    const { likeItem } = this.props;

    var commentRef = firebase
      .firestore()
      .collection('comments.post')
      .doc(likeItem.id)
      .collection('likes')
      .orderBy('creationTime', 'asc')
      .limit(50);
    if (this.state.likes[0]) {
      const lastTime = this.state.likes[0].creationTime;
      commentRef = commentRef.where('creationTime', '>', lastTime);
    }

    commentRef
      .get()
      .then(snapShot => {
        let likes = [];
        snapShot.forEach(doc => {
          likes.push(doc.data());
        });
        this.setState({
          onLoading: false,
          likes: this.state.likes.concat(likes),
        });
      })
      .catch(e => {
        BeerStoryCore.alert_server_connection(e);
        this.setState({ onLoading: false });
      });
  }

  renderLikes() {
    return this.state.likes.map(like => (
      <UserRow
        reloadProfile={true}
        user={like}
        key={like.uid}
        clickable={true}
        subInfo={BeerStoryCore.diffDate(like.creationTime)}
      />
    ));
  }

  renderLoadMore() {
    return (
      <MoreLayout
        className="bs_clickable bs_hover_gray"
        onClick={this.loadLikes.bind(this)}>
        더 보기
      </MoreLayout>
    );
  }

  render() {
    const { likeItem, likeCount } = this.props;
    const isLoadable =
      likeItem && likeCount > 0 && this.state.likes.length < likeCount;

    if (!this.state.onLoading && isLoadable) {
      this.loadLikes();
    }

    return (
      <Modal
        show={!!this.props.likeItem}
        dialogClassName="bs_modal_xsm"
        onHide={this.closeModal.bind(this)}>
        <Modal.Header closeButton>
          <b>좋아요 {likeItem && likeCount > 0 ? ` ${likeCount}개` : ''}</b>
        </Modal.Header>
        <ModalBody>
          {this.renderLikes()}
          {this.state.onLoading ? (
            <BSLoading size={50} />
          ) : isLoadable ? (
            this.renderLoadMore()
          ) : (
            ''
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default LikeModal;
