import React, { Component } from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0px auto;
  width: 100%;
  max-width: 600px;
  text-align: center;
`;

class NotFound extends Component {
  render() {
    return <Wrapper>404 Not Found</Wrapper>;
  }
}

export default NotFound;
