import React, { Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import $ from 'jquery';
import { BeerStoryCore } from '../../include/BeerStoryCore';
import PostItem from './PostItem';
import BSLoading from '../common/BSLoading';
import { BSImage } from '../../res/BSImage';
import UserStore from '../../stores/UserStore';
import {
  ColumnContent,
  FloatingActionButton,
} from '../common/StyledComponents';

const NoPost = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px;
`;
const LoadMoreButton = styled(Button)`
  width: 100%;
`;

@inject(({ beers, posts }) => ({
  loadBeerList: beers.loadBeerList,
  beers: beers.beers,
  addedCount: posts.addedCount,
  fetchPosts: posts.fetchPosts,
}))
@observer
class PostList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: this.props.posts,
      addedCount: 0,
      hidePosts: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    if (!this.props.posts) {
      this.props.fetchPosts(this.fetchCb, this.generateQuery());
    }
    if (!this.props.beers) {
      this.props.loadBeerList();
    }
    this.props.onRef && this.props.onRef(this);
    BeerStoryCore.postList = this;
  }
  componentWillUnmount() {
    this.mounted = false;
    this.props.onRef && this.props.onRef(null);
    BeerStoryCore.postList = null;
  }

  refreshPostList = query => {
    this.setState({ posts: null });
    this.props.fetchPosts(this.fetchCb, query);
  };

  fetchCb = (posts, addedCount) => {
    if (!this.mounted) return;
    this.setState({
      posts: this.state.hidePosts
        ? null
        : Object.assign({}, this.state.posts, posts),
      addedCount: addedCount,
    });
  };

  generateQuery() {
    if (!!this.props.uid) {
      return { uid: this.props.uid };
    } else if (!!this.props.beerId) {
      return { beerId: this.props.beerId };
    }
    return null;
  }

  hide() {
    this.setState({ hidePosts: true, posts: null });
  }
  hidePost = postId => {
    var clean = _.partial(_.omit, _, [postId]);
    this.setState({ posts: clean(this.state.posts) });
  };

  renderPosts() {
    const { posts } = this.state;
    const { beers } = this.props;
    if (!posts || !beers) {
      return;
    }
    return _.map(posts, post => {
      if (!post || Object.keys(post).length <= 0) return;
      return (
        <PostItem
          key={post.id}
          post={post}
          beer={this.props.beers[post.beerId]}
        />
      );
    });
  }

  loadMore(btn) {
    if (!!btn) {
      $(btn).css('display', 'none');
    }

    const { posts } = this.state;
    if (!!posts) {
      let keys = Object.keys(posts);
      let k = keys[keys.length - 1];
      this.props.fetchPosts(
        this.fetchCb,
        this.generateQuery(),
        posts[k].creationTime
      );
    }
  }

  renderFooter() {
    const { posts, addedCount } = this.state;
    if (!posts) {
      return;
    }
    if (Object.keys(posts).length <= 0) {
      return <NoPost>시음 후기가 없습니다.</NoPost>;
    }
    if (addedCount >= BeerStoryCore.POST_LIMITS) {
      $('#bs_list_btn_footer_load_more').css('display', 'block');
      return (
        <LoadMoreButton
          id="bs_list_btn_footer_load_more"
          onClick={e => this.loadMore(e.target)}
          bsStyle="info">
          더 많은 시음 후기 보기
        </LoadMoreButton>
      );
    }
  }

  render() {
    const { posts } = this.state;
    const { beers } = this.props;
    if ((!posts && !this.state.hidePosts) || !beers) {
      if (this.props.hideLoading) return <div />;
      else return <BSLoading />;
    }
    return (
      <div>
        <ColumnContent>
          {this.renderPosts()}
          {this.renderFooter()}
        </ColumnContent>

        <Link
          to={UserStore.getCurrentUid() ? '/posts/new' : '/'}
          onClick={() => {
            if (UserStore.check_user_and_alert(true)) window.scrollTo(0, 0);
          }}>
          <FloatingActionButton>
            <img src={BSImage['ic_add']} alt="Add" />
          </FloatingActionButton>
        </Link>
      </div>
    );
  }
}

export default PostList;
