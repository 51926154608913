import React, { Component } from 'react';
import ImageUploader from 'react-images-upload';
import './ImagePicker.css';
import $ from 'jquery';
import ImageUploaderPreview from './ImageUploaderPreview';

const MAX_IMAGES_SIZE = 5;
class ImagePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: props.defaultImages ? props.defaultImages : [],
    };
    this.addedFile = [];
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(null);
  }

  setImages = imgs => {
    this.setState({ images: imgs });
  };
  getImages = () => {
    return this.previewLayout ? this.previewLayout.getImages() : [];
  };

  onFileSelected = files => {
    const newAdded = [];
    this.addedFile = files.map(file => {
      if (this.addedFile.includes(file) === false) newAdded.push(file);
      return file;
    });
    files = this.state.images.concat(newAdded);
    this.onFileChanged(files);
  };
  onFileChanged = files => {
    const chooseFileButton = $(
      '.bs_image_uploader > .fileContainer .chooseFileButton'
    );
    if (files.length > MAX_IMAGES_SIZE) {
      $.notify(
        { message: `사진은 최대 ${MAX_IMAGES_SIZE}개까지 등록할 수 있습니다.` },
        { type: 'warning' }
      );
      files.splice(MAX_IMAGES_SIZE, Number.MAX_VALUE);
    }
    if (files.length >= MAX_IMAGES_SIZE) {
      chooseFileButton.addClass('disabled');
    } else {
      chooseFileButton.removeClass('disabled');
    }
    this.setState({ images: files });
  };

  render() {
    const { images } = this.state;
    if (images && images.length > 0) {
      this.previewLayout && this.previewLayout.setImages(images);
    }

    return (
      <div>
        <div className="bs_post_edit_row">
          <ImageUploader
            buttonText="Add photo"
            onChange={v => this.onFileSelected(v)}
            withIcon={false}
            withPreview={false}
            withLabel={false}
            errorClass="bs_display_none"
            inputClass="bs_display_none"
            className="bs_image_uploader"
          />
        </div>
        <ImageUploaderPreview
          onRef={ref => (this.previewLayout = ref)}
          onChange={files => this.onFileChanged(files)}
        />
      </div>
    );
  }
}

export default ImagePicker;
