import React, { Component } from 'react';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import { ProfileImg, Blank } from './common/StyledComponents';
import { inject } from 'mobx-react';
import UserStore from '../stores/UserStore';
import { BeerStoryCore } from '../include/BeerStoryCore';

const Wrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #c0c0c0;
  padding: 5px;

  > div {
    display: block;
  }
  :last-child {
    border-bottom: none;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }
`;
const UserInfo = styled.div`
  display: block;
  margin-left: 10px;
`;
const UserName = styled.div`
  font-size: 1em;
  font-weight: bold;
  ${props => !props.isDisabled && `cursor: pointer;`}
`;
const SubInfo = styled.div`
  font-size: 0.75em;
  color: gray;
`;

@inject(({ likeModal }) => ({
  closeLikeModal: likeModal.closeModal,
}))
class UserRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadUser: false,
      user: props.reloadProfile
        ? {
            ...props.user,
            displayName: '', //props.user.displayName,
            photoURL: '', //props.user.photoURL,
          }
        : props.user,
    };

    if (props.reloadProfile) {
      BeerStoryCore.userFromSession(props.user).then(user =>
        this.setState({ user: user })
      );
    }
  }
  loadUserPage = () => {
    if (this.props.isDisabled !== true) this.setState({ loadUser: true });
  };

  render() {
    const { clickable, isDisabled, subInfo } = this.props;
    const { user } = this.state;
    if (!user) return <div />;

    if (this.state.loadUser) {
      const path = `/users/${user.uid}`;
      this.props.closeLikeModal();
      if (
        !(
          user.uid === UserStore.getCurrentUid() &&
          window.location.pathname.startsWith('/users')
        ) &&
        window.location.pathname !== path
      ) {
        return <Redirect to={path} push={true} />;
      }
    }

    const className =
      !isDisabled && clickable ? 'bs_clickable bs_hover_gray' : '';
    return (
      <Wrapper
        className={className}
        clickable={!isDisabled && clickable}
        onClick={this.loadUserPage.bind(this)}>
        <ProfileImg src={user && user.photoURL} />
        <UserInfo>
          <div style={{ display: 'flex' }}>
            {user && user.displayName ? (
              <UserName
                isDisabled={isDisabled}
                onClick={this.loadUserPage.bind(this)}>
                {user && user.displayName}
              </UserName>
            ) : (
              <Blank />
            )}
          </div>
          {subInfo && <SubInfo>{subInfo}</SubInfo>}
        </UserInfo>
      </Wrapper>
    );
  }
}

export default UserRow;
