import React, { Component } from 'react';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import $ from 'jquery';
import { inject } from 'mobx-react';
import UserStore from '../../stores/UserStore';
import { BeerStoryCore } from '../../include/BeerStoryCore';

const RowItem = styled.div`
  text-align: center;
  min-height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #d6d6d6;
  font-size: 0.85em;
  font-weight: ${props => (props.alert ? 'bold' : 'normal')};
  color: ${props => (props.alert ? 'red' : '#333333')};
  display: ${props => (props.hide === true ? 'none' : 'block')};

  :first-child {
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

  :last-child {
    border-bottom: none;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }
`;

@inject(({ postModal, posts }) => ({
  post: postModal.post,
  closeModal: postModal.closeModal,
  deletePost: posts.deletePost,
}))
class PostModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectEdit: null,
    };
  }
  confirmDeletePost() {
    const { post } = this.props;
    if (!post || !post.id) {
      return;
    }

    const self = this;
    confirmAlert({
      title: 'Warning',
      message: '작성한 포스팅을 정말로 삭제하시겠습니까?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            self.props.deletePost(
              post.id,
              () => {
                $.notify(
                  {
                    message: '포스팅이 삭제되었습니다.',
                  },
                  { type: 'warning' }
                );
                BeerStoryCore.postList &&
                  BeerStoryCore.postList.hidePost(post.id);
              },
              e => BeerStoryCore.alert_server_connection(e)
            );
          },
        },
        { label: 'No' },
      ],
    });
  }

  closeModal() {
    this.props.closeModal();
  }

  render() {
    const { redirectEdit } = this.state;
    if (redirectEdit) {
      this.setState({ redirectEdit: null });
      return <Redirect to={`/posts/edit/${redirectEdit}`} push={true} />;
    }
    const { post } = this.props;
    const uid = UserStore.getCurrentUid();
    var targetUrl = null;
    if (!!post) {
      targetUrl = `${window.location.origin.toString()}/posts/${post.id}`;
    }
    return (
      <Modal
        show={!!post}
        dialogClassName="bs_modal_xsm"
        onHide={this.closeModal.bind(this)}>
        <Modal.Body style={{ padding: '0px' }}>
          <CopyToClipboard
            text={targetUrl}
            onCopy={() => {
              if (!!targetUrl) {
                $.notify(
                  {
                    message: '클립보드에 복사하였습니다.',
                    url: targetUrl,
                    target: '_self',
                  },
                  { type: 'success' }
                );
              }
              this.closeModal();
            }}>
            <RowItem className="bs_hover_gray bs_clickable">
              게시글 링크 복사
            </RowItem>
          </CopyToClipboard>
          <RowItem
            onClick={() => {
              this.setState({ redirectEdit: post.id });
              this.closeModal();
            }}
            className="bs_hover_gray bs_clickable"
            hide={!(post && uid === post.uid)}>
            게시글 수정
          </RowItem>
          <RowItem
            alert
            className="bs_hover_gray bs_clickable"
            hide={!(post && uid === post.uid)}
            onClick={() => {
              this.confirmDeletePost();
              this.closeModal();
            }}>
            게시글 삭제
          </RowItem>
          <RowItem
            className="bs_hover_gray bs_clickable"
            onClick={this.closeModal.bind(this)}>
            취소
          </RowItem>
        </Modal.Body>
      </Modal>
    );
  }
}

export default PostModal;
