import UserStore from './UserStore';
import BeersStore from './BeersStore';
import PostStore from './PostStore';
import UserSummaryStore from './UserSummaryStore';

import UserModalStore from './modal/UserModalStore';
import InfoModalStore from './modal/InfoModalStore';
import LikeModalStore from './modal/LikeModalStore';
import PostModalStore from './modal/PostModalStore';
import AddBeerModalStore from './modal/AddBeerModalStore';
import LoadingModalStore from './modal/LoadingModalStore';
import MyBeerModalStore from './modal/MyBeerModalStore';

class RootStore {
  constructor() {
    this.user = new UserStore(this);
    this.beers = new BeersStore(this);
    this.posts = new PostStore(this);
    this.userSummary = new UserSummaryStore(this);

    // Modals
    this.userModal = new UserModalStore(this);
    this.infoModal = new InfoModalStore(this);
    this.likeModal = new LikeModalStore(this);
    this.postModal = new PostModalStore(this);
    this.addBeerModal = new AddBeerModalStore(this);
    this.loadingModal = new LoadingModalStore(this);
    this.myBeerModal = new MyBeerModalStore(this);
  }
}

export default RootStore;
