import { observable, action } from 'mobx';
import _ from 'lodash';
import Store from './Store';
import firebase from '../include/firebase_core';

export default class UserSummaryStore extends Store {
  @observable postCount = null; // 4pts
  @observable beerCount = null; // 1pts
  @observable commentCount = 0; // 0.1pts
  @observable likeCount = 0; // 0.05pts
  @observable myBeerList = {};

  @action fetchPostsByUserId = userId => {
    this.postCount = null;
    this.beerCount = null;
    let commentCount = 0;
    let likeCount = 0;

    var postRef = firebase
      .firestore()
      .collection('posts')
      .where('uid', '==', userId);
    postRef.get().then(data => {
      if (data.size) {
        const docs = data.docs;
        let outBeers = {};
        _.forEach(docs, (v, k) => {
          const post = v.data();
          if (outBeers[post.beerId]) {
            const myBeer = outBeers[post.beerId];
            const postCount = myBeer.postCount || 0;
            const rating = (myBeer.rating || 0) * postCount;
            outBeers[post.beerId] = {
              ...myBeer,
              postCount: postCount + 1,
              rating: (rating + post.rating) / (postCount + 1),
            };
          } else {
            outBeers[post.beerId] = {
              postCount: 1,
              rating: post.rating,
            };
          }
          if (post.commentCount) commentCount += post.commentCount;
          if (post.likeCount) likeCount += post.likeCount;
        });

        this.postCount = docs.length;
        this.beerCount = Object.keys(outBeers).length;
        this.commentCount = commentCount;
        this.likeCount = likeCount;
        this.myBeerList = outBeers;
      } else {
        this.postCount = 0;
        this.beerCount = 0;
        this.commentCount = 0;
        this.likeCount = 0;
        this.myBeerList = {};
      }
    });
  };

  @action getMyBeerList = (myBeers, beerList) => {
    if (!myBeers || !beerList) return {};
    const result = {};
    _.forEach(myBeers, (beer, id) => {
      result[id] = {
        id: id,
        postCount: beer ? beer.postCount : null,
        rating: beer ? beer.rating : null,
      };
      if (beerList[id]) {
        result[id].nameEn = beerList[id].nameEn;
        result[id].nameKr = beerList[id].nameKr;
        result[id].breweryName = beerList[id].breweryName;
      }
    });
    return result;
  };
}
