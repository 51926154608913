import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BeerStoryCore } from '../include/BeerStoryCore';
import { inject, observer } from 'mobx-react';

const BeerLabelWrapper = styled.div`
  width: 80%;
  min-height: 60px;
  background-color: #efefef;
  border: 2px solid #ffda9c;
  border-radius: 5px;
  margin: 10px auto;
  padding: 3px;
  text-align: center;

  a {
    color: inherit;
  }
`;
const BeerName = styled.div`
  text-align: center;
  font-size: 1.5em;
  line-height: 1.5em;
  max-height: 3em;
  overflow: hidden;
  white-space: nowrap;
`;
const BeerNameSub = styled.div`
  text-align: center;
  font-size: 0.8em;
  color: gray;
  overflow: hidden;
  white-space: nowrap;
`;
const BeerInfo = styled.div`
  font-size: 0.75em;
  div {
    display: inline-block;
    margin: auto;
    cursor: ${props => (props.a ? 'pointer' : 'inherit')};
    color: ${props => (props.a ? '#3c97f9 !important' : 'gray')};
  }
  // b {
  //   cursor: ${props => (props.a ? 'pointer' : 'inherit')};
  //   color: ${props => (props.a ? '#3c97f9 !important' : 'gray')};
  // }
`;

@inject(({ infoModal }) => ({ openInfoModal: infoModal.openInfoModal }))
@observer
class BeerLabel extends Component {
  openStyleModal = () => {
    // this.props.openInfoModal(this.props.beer.styleId, 'styleId');
  };

  openBreweryModal = () => {
    // this.props.openInfoModal(this.props.beer.breweryName, 'brewery');
  };

  getDisplayName = beer => {
    if (!!beer.nameEn && beer.nameEn.length > 0) {
      return beer.nameEn;
    }
    return beer.nameKr;
  };

  render() {
    const { beer } = this.props;
    if (!beer) {
      this.props.onNotFound && this.props.onNotFound();
      return <div />;
    }
    const styleId = !!beer.styleId ? beer.styleId * 1 : 0;
    const style =
      0 < styleId && styleId < Object.keys(BeerStoryCore.styles).length
        ? BeerStoryCore.styles[beer.styleId * 1]
        : null;

    return (
      <BeerLabelWrapper>
        <div className="bs_margin_auto">
          <BeerName>
            <Link
              to={`/beers/${beer.id}`}
              onClick={() => window.scrollTo(0, 0)}>
              <b>{this.getDisplayName(beer)}</b>
            </Link>
          </BeerName>
          {beer.nameEn && beer.nameKr && (
            <BeerNameSub>{beer.nameKr}</BeerNameSub>
          )}
          {style && style.name && (
            <BeerInfo>
              <div onClick={this.openStyleModal}>
                {!!style ? style.name : ''}
              </div>
            </BeerInfo>
          )}
          {beer.breweryName && (
            <BeerInfo>
              Brewed by{' '}
              <b onClick={this.openBreweryModal}>{beer.breweryName}</b>
            </BeerInfo>
          )}
          <BeerInfo>
            {!!beer.abv ? `ABV : ${beer.abv}%` : ''}
            {!!beer.abv && !!beer.ibu ? ' | ' : ''}
            {!!beer.ibu ? `IBU : ${beer.ibu}` : ''}
          </BeerInfo>
          <BeerInfo>
            {beer.postCount > 0 &&
              `Posting ${beer.postCount} | Rating ${(beer.rating * 2).toFixed(
                2
              )}`}
          </BeerInfo>
        </div>
      </BeerLabelWrapper>
    );
  }
}

export default BeerLabel;
