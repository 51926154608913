import React, { Component } from 'react';
import $ from 'jquery';
import styled from 'styled-components';
import ImageUploader from 'react-images-upload';
import { Modal, Button } from 'react-bootstrap';
import { inject } from 'mobx-react';
import { ProfileImg } from '../common/StyledComponents';
import { BeerStoryCore } from '../../include/BeerStoryCore';
import firebase from '../../include/firebase_core';
import BSLoading from '../common/BSLoading';

const Body = styled.div`
  @media (min-width: 600px) {
    display: flex;
  }
`;
const ImageWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;

  @media (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }

  > .bs_user_modal_btn_camera {
    display: none;
  }
  :hover {
    > img {
      opacity: 0.5;
    }
    > .bs_user_modal_btn_camera {
      display: block;
    }
  }
`;
const ImageReplaceButton = styled.div`
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  color: white;
  font-size: 30px;
  line-height: 45px;
  background-color: #edb202;
  width: 50px;
  height: 50px;
`;
const ImageButton = styled.div`
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  right: 2px;
  width: 20px;
  height: 20px;
  color: white;
  font-size: 13px;
  line-height: 20px;
  background-color: #edb202;
  top: 2px;
`;
const InfoBlock = styled.div`
  display: block;
  height: 100%;
  margin: auto 10px;
`;
const InfoRow = styled.div`
  display: flex;
  color: #333333;
  margin: 5px 0 0 0;
`;
const InfoLabel = styled.div`
  width: 30%;
  min-width: 70px;
  margin: auto;
`;
const InfoEdit = styled.input`
  min-width: 160px;
  border: 1px solid #d6d6d6;
  padding: 2px;
  ${props => props.readOnly && `background-color: #e3e3e3`};
`;
const InfoGuide = styled.div`
  margin: 0 0 6px 70px;
  font-size: 10px;
  line-height: 12px;
  min-height: 12px;
  color: red;
`;

@inject(({ userModal }) => ({
  user: userModal.user,
  updateUser: userModal.updateUser,
  closeModal: userModal.closeModal,
  editModalShowing: userModal.editModalShowing,
}))
class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      onLoading: false,
    };
  }

  onEnterModal = () => {
    this.setState({ user: this.props.user, onLoading: false });
  };

  onCloseModal = () => {
    this.props.closeModal();
    this.setState({ nameValid: '', emailValid: '', onLoading: false });
  };

  validateName = name => {
    const length = name ? name.length : 0;
    return length < 5 || length > 15;
  };
  validateEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  saveButtonEnabled = () => {
    const { user } = this.state;
    if (!user || !this.props.user) return false;

    const isEdited =
      user.displayName !== this.props.user.displayName ||
      user.email !== this.props.user.email ||
      user.photoURL !== this.props.user.photoURL ||
      this.state.profileFile;
    return (
      this.validateName(user.name) && this.validateEmail(user.email) && isEdited
    );
  };
  checkUpdated = (user, newUser) => {
    Object.keys(user).forEach(key => {
      if (user[key] !== newUser[key]) return false;
    });
    return true;
  };
  updateUser = () => {
    const { user, profileFile } = this.state;
    if (profileFile && profileFile.name) {
      const ext = profileFile.name.split('.').pop();
      const fileName = `${user.uid}_${new Date().getTime()}.${ext}`;
      firebase
        .storage()
        .ref()
        .child(`profile/${fileName}`)
        .put(profileFile)
        .then(snapshot => {
          snapshot.ref
            .getDownloadURL()
            .then(url => {
              this.setState({
                onLoading: true,
                profileFile: null,
                user: { ...user, photoURL: url },
              });
              this.updateUser();
            })
            .catch(e => {
              BeerStoryCore.alert_server_connection(e);
              this.setState({ onLoading: false });
            });
        })
        .catch(e => {
          BeerStoryCore.alert_server_connection(e);
          this.setState({ onLoading: false });
        });
      return;
    }
    this.props.updateUser(user, newUser => {
      if (newUser) {
        if (
          this.checkUpdated(user, newUser) &&
          typeof this.props.onUserUpdated === 'function'
        ) {
          $.notify(
            {
              message: '변경된 내용이 적용되는데 3분 이상 소요될 수 있습니다.',
            },
            { type: 'success' }
          );
          this.props.onUserUpdated(newUser);
        }
      } else {
        BeerStoryCore.alert_server_connection();
      }
    });
    this.setState({ onLoading: true });
  };

  onProfileImageSelected = v => {
    if (v && v.length > 0) {
      var reader = new FileReader();
      reader.onload = function() {
        $(`#bs_user_modal-image_profile`).css(
          'content',
          `url("${reader.result}")`
        );
      };
      reader.readAsDataURL(v[v.length - 1]);
      this.setState({ profileFile: v[v.length - 1] });
    }
  };

  render() {
    const { user } = this.state;
    //{displayName, email, photoURL, uid}

    return (
      <Modal
        show={this.props.editModalShowing}
        onHide={this.onCloseModal}
        onEnter={this.onEnterModal.bind(this)}
        dialogClassName="bs_modal_info">
        {user && (
          <div>
            <Modal.Header closeButton>
              <b>프로필 편집</b>
            </Modal.Header>
            <Modal.Body>
              <Body>
                <ImageWrapper id={'bs_user_modal-input_image'}>
                  <ProfileImg
                    id={'bs_user_modal-image_profile'}
                    size={100}
                    src={user.photoURL}
                  />
                  <ImageButton
                    onClick={() => {
                      $('#bs_user_modal-image_profile').css('content', '');
                      this.setState({ user: { ...user, photoURL: '' } });
                    }}>
                    <i className="fas fa-times" />
                  </ImageButton>
                  <ImageReplaceButton
                    className={'bs_user_modal_btn_camera'}
                    onClick={() =>
                      $('#bs_user_modal-input_image .chooseFileButton').click()
                    }>
                    <i className="fas fa-camera" />
                  </ImageReplaceButton>
                  <ImageUploader
                    style={{ display: 'none' }}
                    onChange={v => this.onProfileImageSelected(v)}
                    withIcon={false}
                    withPreview={false}
                    withLabel={false}
                    singleImage={true}
                    errorClass="bs_display_none"
                    inputClass="bs_display_none"
                    className="bs_image_uploader"
                  />
                </ImageWrapper>
                <InfoBlock>
                  <InfoRow>
                    <InfoLabel>이름</InfoLabel>
                    <InfoEdit
                      type="text"
                      onChange={e => {
                        var nameValid = this.validateName(e.target.value)
                          ? '5~15자 내외로 입력가능합니다.'
                          : '';
                        var value = e.target.value.substr(0, 15);
                        this.setState({
                          nameValid: nameValid,
                          user: { ...user, displayName: value },
                        });
                      }}
                      value={user.displayName || ''}
                    />
                  </InfoRow>
                  <InfoGuide>{this.state.nameValid}</InfoGuide>
                  <InfoRow>
                    <InfoLabel>이메일</InfoLabel>
                    <InfoEdit
                      type="email"
                      readOnly={this.props.user && this.props.user.email}
                      onChange={e => {
                        var emailValid =
                          this.validateEmail(e.target.value) === false
                            ? '이메일 형식을 확인하세요.'
                            : '';
                        this.setState({
                          emailValid: emailValid,
                          user: { ...user, email: e.target.value },
                        });
                      }}
                      value={user.email || ''}
                    />
                  </InfoRow>
                  <InfoGuide>{this.state.emailValid}</InfoGuide>
                </InfoBlock>
              </Body>
            </Modal.Body>
            <Modal.Footer>
              {this.state.onLoading ? (
                <BSLoading size={50} />
              ) : (
                <Button
                  bsStyle="primary"
                  style={{ paddingLeft: '20px', paddingRight: '20px' }}
                  disabled={!this.saveButtonEnabled()}
                  onClick={this.updateUser.bind(this)}>
                  저장
                </Button>
              )}
            </Modal.Footer>
          </div>
        )}
      </Modal>
    );
  }
}

export default UserModal;
