import React, { Component } from 'react';
import styled from 'styled-components';
import { inject } from 'mobx-react';
import { Button } from 'react-bootstrap';
import { BSImage } from '../res/BSImage';
import BSLoading from './common/BSLoading';
import NotFound from './common/NotFound';
import { ProfileImg } from './common/StyledComponents';
import UserStore from '../stores/UserStore';
import UserModal from './modal/UserModal';

const Wrapper = styled.div`
  background: white;
  padding: 15px;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  margin: 0px auto;
`;
const ProfileLayout = styled.div`
  display: inline-flex;
`;
const ProfileInfo = styled.div`
  display: table;
  margin: auto 0;
  text-align: initial;
  margin-left: 20px;
`;
const ProfileName = styled.div`
  font-weight: bold;
  font-size: 17px;
  line-height: 19px;
  i {
    cursor: pointer;
    margin-left: 10px;
  }
`;
const ProfileEditButton = styled(Button)`
  height: 20px;
  line-height: 10px;
  min-width: 120px;
  width: 100%;
  margin-top: 8px;
`;
const SummaryWrapper = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 10px;
  > div {
    display: inline-block;
    min-width: 60px;
    margin-right: 10px;
  }
  > div:last-child {
    margin-right: 0;
  }
  i {
    font-size: 16px;
    margin-right: 5px;
  }
`;
const Clickable = styled.div`
  ${props => !props.disabled && `cursor: pointer;`}
`;
// const AdditionalInfo = styled.div`
//   text-align: initial;
//   display: flex;
// `;
// const ProfileAlias = styled.div`
//   margin-top: 8px;
//   margin-right: 15px;
//   font-size: 14px;
//   i {
//     margin-left: 5px;
//   }
// `;

@inject(({ user, userSummary, userModal, beers, myBeerModal }) => ({
  user: user.user,
  userUpdated: user.setUser,
  loadUserInfo: user.loadUserInfo,
  postCount: userSummary.postCount,
  beerCount: userSummary.beerCount,
  fetchPostsByUserId: userSummary.fetchPostsByUserId,
  myBeerList: userSummary.myBeerList,
  getMyBeerList: userSummary.getMyBeerList,
  openDialog: userModal.openUserModal,
  beers: beers.beers,
  openBeersModal: myBeerModal.openMyBeerModal,
}))
class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { loadFailed: false };
  }
  componentDidMount() {
    this.mounted = true;
    this.props.onRef && this.props.onRef(this);
    const { uid } = this.props;
    if (uid) {
      this.loadUserInfo(uid);
    } else {
      this.setState({ loadFailed: true });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    this.props.onRef && this.props.onRef(null);
  }

  loadUserInfo = uid => {
    const self = this;
    this.props.loadUserInfo(uid, user => {
      if (!self.mounted) return;
      self.setState({ user: user, loadFailed: user === null });
    });
    if (this.props.showSummary) {
      this.props.fetchPostsByUserId(uid);
    }
  };

  renderEditButton = () => {
    const { user } = this.props;
    return (
      <ProfileEditButton
        bsStyle="info"
        bsSize="sm"
        onClick={() => this.props.openDialog(user)}>
        프로필 수정
      </ProfileEditButton>
    );
  };

  openMyBeersModal = () => {
    const myBeers = this.props.getMyBeerList(
      this.props.myBeerList,
      this.props.beers
    );
    this.props.openBeersModal(myBeers, this.props.user);
  };

  render() {
    const { loadFailed } = this.state;
    const { user } = this.props;
    if (!user) {
      if (loadFailed) {
        this.props.onNotFound && this.props.onNotFound();
        return <NotFound />;
      } else {
        return <BSLoading />;
      }
    }

    const showEditButton =
      user.uid === UserStore.getCurrentUid() && !this.props.hideEditButton;

    return (
      <Wrapper>
        <div style={{ display: 'inline-block' }}>
          <ProfileLayout>
            <ProfileImg
              size={80}
              src={user.photoURL ? user.photoURL : BSImage['profile_default']}
            />
            <ProfileInfo>
              <ProfileName>{user.displayName}</ProfileName>
              {showEditButton && this.renderEditButton()}
              {this.props.showSummary && (
                <SummaryWrapper>
                  <div>
                    <Clickable disabled>
                      <i className="fas fa-comment-dots" />{' '}
                      {this.props.postCount && !isNaN(this.props.postCount)
                        ? this.props.postCount
                        : '-'}
                    </Clickable>
                  </div>
                  <div>
                    <Clickable onClick={this.openMyBeersModal.bind(this)}>
                      <i className="fas fa-beer" />{' '}
                      {this.props.beerCount && !isNaN(this.props.beerCount)
                        ? this.props.beerCount
                        : '-'}
                    </Clickable>
                  </div>
                  <div className="bs_display_none">
                    <Clickable>
                      <i className="fas fa-thumbs-up" />{' '}
                      {/* {this.props.postCount && !isNaN(this.props.postCount)
                      ? this.props.postCount
                      : '-'} */}
                    </Clickable>
                  </div>
                </SummaryWrapper>
              )}
            </ProfileInfo>
          </ProfileLayout>
          {/* <AdditionalInfo>
            <ProfileAlias>
              맥주 마스터 <i className="far fa-question-circle" />
            </ProfileAlias>
            <ProfileAlias>
              라거 매니아 <i className="far fa-question-circle" />
            </ProfileAlias>
          </AdditionalInfo> */}
        </div>
        <UserModal
          onUserUpdated={updatedUser => {
            this.props.userUpdated(updatedUser);
          }}
        />
      </Wrapper>
    );
  }
}

export default UserInfo;
