import React, { Component } from 'react';
import styled from 'styled-components';
import BSFooter from '../common/BSFooter';
import BSLoading from '../common/BSLoading';
import { ColumnContent } from '../common/StyledComponents';

const Wrapper = styled.div`
  padding: 20px 20px 10px 20px;
`;
const Contents = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #d6d6d6;
`;

export default class TermsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
  }

  readTextFile() {
    let fileId = this.props.match.params.id;
    this.file = require('../../res/terms.txt');
    if (fileId && fileId.toLowerCase() === 'privacy') {
      this.file = require('../../res/privacy.txt');
    }

    var rawFile = new XMLHttpRequest();
    rawFile.open('GET', this.file, true);
    let self = this;
    rawFile.onreadystatechange = function() {
      if (rawFile.readyState === 4) {
        self.setState({ text: rawFile.responseText });
      }
    };
    rawFile.send();
  }

  render() {
    if (!this.file) {
      this.readTextFile();
    }

    const { text } = this.state;

    return (
      <ColumnContent border>
        <Wrapper>
          {text.length > 0 ? (
            <Contents>
              {text.split('\n').map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </Contents>
          ) : (
            <BSLoading />
          )}
          <BSFooter />
        </Wrapper>
      </ColumnContent>
    );
  }
}
