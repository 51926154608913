import React, { Component } from 'react';
import styled from 'styled-components';
import classNames from 'classnames/bind';
import firebase from '../../include/firebase_core';
import { confirmAlert } from 'react-confirm-alert';
import { inject } from 'mobx-react';
import $ from 'jquery';
import UserInfo from '../UserInfo';
import FirebaseLogin from '../common/FirebaseLogin';
import AboutBS from '../common/AboutBS';
import BSFooter from '../common/BSFooter';
import { BeerStoryCore } from '../../include/BeerStoryCore';
import { BSImage } from '../../res/BSImage';
import { ColumnContent } from '../common/StyledComponents';
import UserStore from '../../stores/UserStore';

const SettingPanel = styled.div`
  padding-top: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid #c0c0c0;
  box-shadow: 0px 1px #dfdfdf;
  a {
    font-size: 1em;
    color: #c0c0c0;
  }
  .collapse[aria-expanded='false'] {
    display: block;
    overflow: hidden;
    height: 0em !important;
  }
  .collapse.show[aria-expanded='false'] {
    height: auto !important;
    border-top: 1px solid #d6d6d6;
    background-color: white;
    padding: 3px;
  }
  .collapsing[aria-expanded='false'] {
    height: 0em !important;
  }
`;
const SettingRow = styled.div`
  width: 100%;
  line-height: 1.5em;
  font-size: 1em;
  vertical-align: center;
  padding: 0.5em;
  background: white;
  border-top: 1px solid #c0c0c0;
  cursor: pointer;
  :hover {
    background: #dfdfdf;
  }
`;
const SettingItemImg = styled.img`
  margin-right: ${props => (props.arrow ? 0 : '7px')};
  vertical-align: center;
  width: ${props => (props.arrow ? '1.5em' : '1.8em')};
  height: ${props => (props.arrow ? '1.5em' : '1.8em')};
  float: ${props => (props.arrow ? 'right' : 'none')};
  @media (max-width: 300px) {
    display: ${props => (props.arrow ? 'none' : 'flex')};
  }
  -webkit-transform: ${props =>
    props.rotate ? `rotate(${props.rotate}deg)` : 'none'};
  -moz-transform: r
    ${props => (props.rotate ? `rotate(${props.rotate}deg)` : 'none')};
  -o-transform: ${props =>
    props.rotate ? `rotate(${props.rotate}deg)` : 'none'};
  -ms-transform: ${props =>
    props.rotate ? `rotate(${props.rotate}deg)` : 'none'};
  transform: ${props => (props.rotate ? `rotate(${props.rotate}deg)` : 'none')};
`;

@inject(({ user, userModal }) => ({
  user: user.user,
  loadUserInfo: user.loadUserInfo,
  openUserModal: userModal.openUserModal,
}))
class SettingsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aboutIsShowing: false,
    };
  }

  reloadPage() {
    this.props.history.replace('/settings');
  }
  onSignOut = (isLeaveAccount = false) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        var msg = '로그아웃 되었습니다.';
        if (isLeaveAccount) {
          msg = '탈퇴 되었습니다.';
        }
        $.notify(
          { message: msg },
          { type: 'warning', delay: 300, onClose: this.reloadPage.bind(this) }
        );
      })
      .catch(e => BeerStoryCore.alert_server_connection(e));
  };
  confirmSignOut = () => {
    confirmAlert({
      message: '정말로 로그아웃하시겠습니까?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.onSignOut(false),
        },
        {
          label: 'No',
        },
      ],
    });
  };

  confirmLeave = uid => {
    confirmAlert({
      title: 'Warning',
      message:
        '회원 탈퇴 시 기존에 작성한 내용이 모두 사라집니다. 정말로 탈퇴하시겠습니까?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            /* TODO: delete all posts by uid
             * then signOut
             */
            this.onSignOut(true);
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  openUserEditModal = () => {
    if (this.props.user.uid) {
      this.props.openUserModal(this.props.user);
    } else {
      this.props.loadUserInfo(UserStore.getCurrentUid(), user => {
        if (!this.mounted) return;
        this.props.openUserModal(user);
      });
    }
  };

  render() {
    const uid = UserStore.getCurrentUid();

    return (
      <ColumnContent border backgroundColor={'transparent'}>
        {uid && uid.length > 0 ? (
          <UserInfo uid={uid} style={{ marginBottom: '1em' }} />
        ) : (
          <FirebaseLogin />
        )}
        <div>
          <SettingPanel>
            <SettingRow
              className="bs_settings_menu_item"
              onClick={() =>
                this.setState({
                  aboutIsShowing: !this.state.aboutIsShowing,
                })
              }>
              <SettingItemImg src={BSImage['ic_bs_bw']} />
              About 비어스토리
              <SettingItemImg
                arrow
                rotate={this.state.aboutIsShowing ? 270 : 90}
                src={BSImage['ic_arrow_right']}
              />
            </SettingRow>
            <div
              className={classNames({
                bs_display_none: !this.state.aboutIsShowing,
              })}>
              <AboutBS />
            </div>

            <SettingRow className="bs_display_none">
              <SettingItemImg src={BSImage['ic_notice']} />
              공지 사항
              <SettingItemImg
                arrow
                rotate={90}
                src={BSImage['ic_arrow_right']}
              />
            </SettingRow>
          </SettingPanel>

          <SettingPanel>
            <SettingRow onClick={() => this.props.history.push('/terms')}>
              <SettingItemImg src={BSImage['ic_service']} />
              서비스 이용 약관
              <SettingItemImg arrow src={BSImage['ic_arrow_right']} />
            </SettingRow>
            <SettingRow
              onClick={() => this.props.history.push('/terms/privacy')}>
              <SettingItemImg src={BSImage['ic_privacy']} />
              개인 정보 처리 방침
              <SettingItemImg arrow src={BSImage['ic_arrow_right']} />
            </SettingRow>
            <SettingRow
              onClick={() => {
                window.location.href = 'mailto:doodle.fac@gmail.com';
              }}>
              <SettingItemImg src={BSImage['ic_mail']} />
              서비스 이용 문의
              <SettingItemImg arrow src={BSImage['ic_arrow_right']} />
            </SettingRow>
          </SettingPanel>

          <SettingPanel
            className={classNames({
              bs_display_none: !uid || uid.length <= 0,
            })}>
            <SettingRow onClick={() => this.confirmSignOut()}>
              <SettingItemImg src={BSImage['ic_signout']} />
              로그아웃
              <SettingItemImg arrow src={BSImage['ic_arrow_right']} />
            </SettingRow>

            {/* <SettingRow onClick={() => this.confirmLeave(uid)}>
              <SettingItemImg src={BSImage['ic_leave']} />
              회원 탈퇴
              <SettingItemImg arrow src={BSImage['ic_arrow_right']} />
            </SettingRow> */}
          </SettingPanel>
        </div>

        <BSFooter />
      </ColumnContent>
    );
  }
}

export default SettingsTab;
