import * as firebase from 'firebase';

// Initialize Firebase
var config = {
  // apiKey: 'AIzaSyBFn6GueOONIOWvX4mrgKXvuBiSIMz4hAY',
  // authDomain: 'beerstory-asahi.firebaseapp.com',
  // databaseURL: 'https://beerstory-asahi.firebaseio.com',
  // projectId: 'beerstory-asahi',
  // storageBucket: 'beerstory-asahi.appspot.com',
  // messagingSenderId: '1000587737651',
  apiKey: ' AIzaSyBjGXGMRStlxKd6oLvNxOaz5X9d2gbbBOQ ',
  authDomain: 'beer-story.firebaseapp.com',
  databaseURL: 'https://beer-story.firebaseio.com',
  projectId: 'beer-story',
  storageBucket: 'beer-story.appspot.com',
  messagingSenderId: '413455279170',
};
if (firebase.apps.length === 0) {
  firebase.initializeApp(config);

  const firestore = firebase.firestore();
  const settings = {};
  firestore.settings(settings);
}

export default firebase;
