import React, { Component } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from '../../include/firebase_core';

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/settings',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
};

class FirebaseLogin extends Component {
  render() {
    return (
      <div>
        <div
          style={{
            minHeight: '100px',
            padding: '20px',
            display: 'block',
            textAlign: 'center',
          }}>
          <p>회원가입을 하시면 더 많은 기능을 활용할 수 있습니다.</p>

          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </div>
      </div>
    );
  }
}

export default FirebaseLogin;
