import React, { Component } from 'react';
import styled from 'styled-components';
import BeerLabel from '../BeerLabel.js';
import PostList from '../post/PostList';
import { inject, observer } from 'mobx-react';

const BeerInfo = styled.div`
  background: white;
  padding: 10px 0px;
  display: block;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
`;

@inject(({ beers }) => ({
  loadBeerList: beers.loadBeerList,
  beers: beers.beers,
}))
@observer
class BeerTab extends Component {
  componentDidMount() {
    const beerId = this.props.match.params.id;
    if (!this.props.beers || !this.props.beers[beerId]) {
      this.props.loadBeerList();
    }
  }

  onBeerNotFound = () => {
    this.postList && this.postList.hide();
  };

  render() {
    const beerId = this.props.match.params.id;
    const { beers } = this.props;

    return (
      <>
        <BeerInfo>
          <BeerLabel
            beer={beers && beers[beerId]}
            onNotFound={this.onBeerNotFound.bind(this)}
          />
        </BeerInfo>

        <PostList beerId={beerId} onRef={ref => (this.postList = ref)} />
      </>
    );
  }
}

export default BeerTab;
