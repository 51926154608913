import React, { Component } from 'react';
import Radar from 'react-d3-radar';

class FlavorChart extends Component {
  constructor(props) {
    super(props);

    const { post } = this.props;

    let variables = [];
    let flavors = {};
    const keys = Object.keys(post.flavors);
    var i = 0;
    var k;
    for (i = 0; i < keys.length; i++) {
      k = keys[i];
      variables.push({ key: k, label: k });
      flavors[k] = (post.flavors[k] + 1) * 2 - 1;
      if (flavors[k] > 10) flavors[k] = 10;
    }
    //1>3 | 2>5 | 3>7 | 4>9 | 5>10
    flavors[k] = flavors[k] + 0.1; // library issue(set not all same num)
    let sets = [
      {
        key: 'flavors',
        label: 'flavors',
        values: flavors,
        color: '#ffc107',
      },
    ];

    this.state = {
      chartData: {
        variables: variables,
        sets: sets,
      },
    };
  }

  render() {
    var { size } = this.props;
    if (!size) {
      size = 180;
    }

    return (
      <div
        className="bs_radar_chart_flavors"
        id={`bs_flavors-${this.props.post.id}`}
        style={{
          width: size,
          height: size,
          margin: '10px auto',
          display: 'inline-block',
        }}>
        <Radar
          width={100}
          height={100}
          padding={10}
          scaleColor={'red'}
          domainMax={10}
          style={{ ringColor: '#e3e3e3', axisColor: '#d6d6d6' }}
          data={this.state.chartData}
        />
      </div>
    );
  }
}

export default FlavorChart;
