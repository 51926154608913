import { observable, action } from 'mobx';
import Store from '../Store';

export default class LikeModalStore extends Store {
  @observable likeItem = null;
  @observable likeCount = null;

  @action openLikeModal = (likeItem, likeCount) => {
    this.likeItem = likeItem;
    this.likeCount = likeCount;
  };
  @action closeModal = () => {
    this.likeItem = null;
    this.likeCount = null;
  };
}
