import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { inject } from 'mobx-react';
import { BeerStoryCore } from '../../include/BeerStoryCore';

const InfoDescription = styled.div`
  font-size: 0.8em;
  padding-bottom: 5px;
  border-bottom: 1px solid #d6d6d6;
  display: ${props => (props.visible === true ? 'block' : 'none')};
`;

@inject(({ infoModal }) => ({
  data: infoModal.data,
  dataType: infoModal.dataType,
  closeModal: infoModal.closeModal,
}))
class InfoModal extends Component {
  convertData() {
    if (!this.props.data || !this.props.dataType) {
      return {};
    }
    const d = this.props.data;

    if (this.props.dataType === 'styleId') {
      const styles = BeerStoryCore.styles;

      const style = styles[parseInt(d)];
      var infos = {};
      if (!!style.parent && styles[style.parent]) {
        infos['ancestor'] = `${styles[style.parent].name} > ${style.name}`;
      }
      return { ...style, title: style.name, infos: infos };
    }

    if (this.props.dataType === 'brewery') {
      return { title: d };
    }
    return {};
  }
  renderInfoNav(infos) {
    if (!infos || !infos.ancestor) return;
    return <div className="bs_text_sub">{infos.ancestor}</div>;
  }

  renderInfos(infos) {
    if (!infos || Object.keys(infos).size < 0) return;
  }

  render() {
    const data = this.convertData();
    return (
      <Modal
        show={!!data.title}
        onHide={this.props.closeModal}
        dialogClassName="bs_modal_info">
        <Modal.Header closeButton>{data.title}</Modal.Header>
        <Modal.Body>
          <InfoDescription visible={data.desc && data.desc.length > 0}>
            {data.desc}
          </InfoDescription>
          {this.renderInfoNav(data.infos)}
          {this.renderInfos(data.infos)}
        </Modal.Body>
      </Modal>
    );
  }
}

export default InfoModal;
