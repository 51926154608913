import React, { Component } from 'react';
import styled from 'styled-components';

class CircleNumber extends Component {
  render() {
    const height = this.props.height;
    const NumberWrapper = styled.div`
      height: ${height + 'px'};
      width: ${height + 'px'};
      margin: auto 0;
    `;

    return (
      <NumberWrapper>
        <svg width={height} height={height}>
          <rect
            x={1}
            y={1}
            width={height - 2}
            height={height - 2}
            rx={4}
            ry={4}
            fill="#fff"
            stroke="#ffc107"
            strokeWidth="1"
          />
          <text
            x="50%"
            y="75%"
            textAnchor="middle"
            fill="#ffc107"
            fontSize={height * 0.75 + 'px'}>
            {this.props.number}
          </text>
        </svg>
      </NumberWrapper>
    );
  }
}

export default CircleNumber;
