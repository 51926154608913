import { observable, action } from 'mobx';
import Store from '../Store';

export default class PostModalStore extends Store {
  @observable post = null;

  @action openPostModal = post => {
    this.post = post;
  };
  @action closeModal = () => {
    this.post = null;
  };
}
