import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import classNames from 'classnames/bind';

import BSHeader from './components/common/BSHeader';

import BeerTab from './components/tabs/BeerTab';
import UserTab from './components/tabs/UserTab';
import SearchTab from './components/tabs/SearchTab';
import SettingsTab from './components/tabs/SettingsTab';
import TermsTab from './components/tabs/TermsTab';
import PostIndexTab from './components/tabs/PostIndexTab';
import PostEdit from './components/post/PostEdit';
import PostItem from './components/post/PostItem';

import AddBeerModal from './components/modal/AddBeerModal';
import InfoModal from './components/modal/InfoModal';
import PostModal from './components/modal/PostModal';
import LikeModal from './components/modal/LikeModal';
import LoadingModal from './components/modal/LoadingModal';
import { NotificationContainer } from 'react-notifications';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-notifications/lib/notifications.css';
import { BeerStoryCore } from './include/BeerStoryCore';
import MyBeerModal from './components/modal/MyBeerModal';

class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <div>
            <BSHeader />
            <div
              className={classNames('bs_main_contents', {
                bs_app: BeerStoryCore.from_bs_app === true,
              })}>
              <Switch>
                <Route path="/posts/new" component={PostEdit} />
                <Route path="/posts/edit/:id" component={PostEdit} />

                <Route path="/search" component={SearchTab} />

                <Route path="/users/:id" component={UserTab} />
                <Route path="/users" component={UserTab} />

                <Route path="/beers/:id" component={BeerTab} />

                <Route path="/settings" component={SettingsTab} />

                <Route path="/posts/:id" component={PostItem} />

                <Route path="/terms/:id" component={TermsTab} />
                <Route path="/terms/" component={TermsTab} />

                <Route path="/" component={PostIndexTab} />
              </Switch>
            </div>
            <LikeModal />
            <PostModal />
            <MyBeerModal />
          </div>
        </BrowserRouter>
        <AddBeerModal />
        <InfoModal />
        <LoadingModal />
        <NotificationContainer />
      </div>
    );
  }
}

export default App;
