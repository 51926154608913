import { observable, action } from 'mobx';
import Store from '../Store';
import firebase from '../../include/firebase_core';
import { BeerStoryCore } from '../../include/BeerStoryCore';

export default class UserModalStore extends Store {
  @observable user = null;
  @observable updatedUser = null;
  @observable editModalShowing = false;

  @action openUserModal = user => {
    this.user = user;
    this.editModalShowing = true;
  };
  @action updateUser = (user, cb) => {
    if (!user || !user.uid) {
      if (typeof cb === 'function') cb(null);
      return;
    }
    firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .update(user)
      .then(() => {
        this.user = user;
        this.editModalShowing = false;
        BeerStoryCore.putUserToSession(user);
        if (typeof cb === 'function') cb(user);
      })
      .catch(() => {
        if (typeof cb === 'function') cb(null);
      });
  };
  @action closeModal = () => {
    this.editModalShowing = false;
  };
}
