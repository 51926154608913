import { observable, action } from 'mobx';
import _ from 'lodash';
import Store from './Store';
import firebase from '../include/firebase_core';
import { BeerStoryCore } from '../include/BeerStoryCore';

export default class PostStore extends Store {
  @observable addedCount = 0;

  fetchPost = (postId, cb) => {
    var postRef = firebase
      .firestore()
      .collection('posts')
      .doc(postId);
    postRef.get().then(data => {
      if (typeof cb === 'function') {
        cb(data.data());
      }
    });
  };

  fetchPosts = (cb, query = null, timestamp = null) => {
    var postRef = firebase.firestore().collection('posts');
    if (!!query && Object.keys(query).length > 0) {
      const key = Object.keys(query)[0];
      const val = query[key];
      postRef = postRef.where(key, '==', val);
    }
    postRef = postRef
      .orderBy('creationTime', 'desc')
      .limit(BeerStoryCore.POST_LIMITS);
    if (timestamp) {
      postRef = postRef.where('creationTime', '<', timestamp);
    }

    postRef
      .get()
      .then(data => {
        const docs = data.docs;
        let out = {};
        _.forEach(docs, (v, k) => {
          out[v.id] = v.data();
          out[v.id].id = v.id;
        });
        cb(out, Object.keys(docs).length);
      })
      .catch(e => {
        cb({}, 0);
      });
  };

  @action deletePost = (postId, cb, er) => {
    var postRef = firebase
      .firestore()
      .collection('posts')
      .doc(postId);
    postRef
      .delete()
      .then(data => {
        if (typeof cb === 'function') {
          cb();
        }
      })
      .catch(e => {
        if (typeof er === 'function') {
          er();
        }
      });
  };
}
