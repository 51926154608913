import React, { Component } from 'react';
import styled from 'styled-components';
import { inject } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import BeerItem from '../common/BeerItem';
import UserRow from '../UserRow';

const ModalBody = styled(Modal.Body)`
  padding: 0px;
  max-height: 300px;
  overflow-y: auto;
`;
const BeerWrapper = styled.div`
  border-bottom: 1px solid #c0c0c0;
  :last-child {
    border-bottom: none;
  }
`;

@inject(({ myBeerModal }) => ({
  myBeers: myBeerModal.myBeers,
  user: myBeerModal.user,
  closeModal: myBeerModal.closeModal,
}))
class MyBeerModal extends Component {
  closeModal() {
    this.props.closeModal();
  }

  renderBeers() {
    return _.map(_.orderBy(this.props.myBeers, ['rating'], ['desc']), beer => (
      <BeerWrapper key={beer.id} onClick={this.closeModal.bind(this)}>
        <BeerItem beer={beer} />
      </BeerWrapper>
    ));
  }

  render() {
    const { myBeers } = this.props;
    let subInfo = '';
    if (myBeers) {
      const length = Object.keys(this.props.myBeers).length;
      if (length > 0) {
        subInfo = `총 ${length} 종`;
      }
    }
    return (
      <Modal
        show={!!this.props.myBeers}
        dialogClassName="bs_modal_md"
        onHide={this.closeModal.bind(this)}>
        <Modal.Header style={{ padding: '5px' }} closeButton>
          <UserRow
            reloadProfile={true}
            user={this.props.user}
            isDisabled={true}
            subInfo={subInfo}
          />
        </Modal.Header>
        <ModalBody>{this.renderBeers()}</ModalBody>
      </Modal>
    );
  }
}

export default MyBeerModal;
