import React, { Component } from 'react';
import styled from 'styled-components';
import Rating from 'react-rating';
import ShowMoreText from 'react-show-more-text';
import BeerLabel from '../BeerLabel.js';
import { BeerStoryCore } from '../../include/BeerStoryCore';
import { BSImage } from '../../res/BSImage';
import PostFooter from './PostFooter';
import { Stars } from '../common/StyledComponents';
import UserRow from '../UserRow';
import { inject, observer } from 'mobx-react';
import ImageFlavorSliders from '../common/ImageFlavorSlider';
import FlavorChart from '../post/FlavorChart';
import BSLoading from '../common/BSLoading.js';

const Wrapper = styled.div`
  margin: ${props => (props.paramId ? '15px auto' : '15px 0px')};
  width: ${props => (props.paramId ? '80%' : '100%')};
  max-width: 600px;
  border-radius: 0.5rem;
  box-shadow: 0.5px 0.5px #a0a0a0;
  a {
    color: inherit;
  }
`;
const Contents = styled.div`
  padding: 5px;
`;
const UserInfo = styled.div`
  border-bottom: 1px solid #c0c0c0;
  display: flex;
`;
const ButtonWrapper = styled.div`
  height: 50px;
  position: absolute;
  right: 7px;
`;
const MoreButton = styled.img`
  width: 30px;
  height: 30px;
  margin-top: 8px;
  cursor: pointer;
`;
const Summary = styled.div`
  width: 100%;
  margin: 15px auto -10px auto;
  display: block;
  text-align: center;
`;
const FlavorImageWrapper = styled.div`
  height: 200px;
  text-align: center;
`;
const Notes = styled.div`
  width: 100%;
  padding: 10px 5px;
  font-size: 0.8em;
  line-height: 1.5;
  border-top: 1px solid #d6d6d6;
  margin-top: 10px;

  p[aria-expanded='true'] {
    display: block;
    height: auto !important;
  }
  p.collapse[aria-expanded='false'] {
    display: block;
    overflow: hidden;
    height: 3.1em !important;
  }
  p.collapse.show[aria-expanded='false'] {
    height: auto !important;
  }
  p.collapsing[aria-expanded='false'] {
    height: 3.1em !important;
  }
  a {
    font-size: 1em;
    color: #c0c0c0;
  }
  a.collapsed:after {
    content: '더 보기';
  }
  a:not(.collapsed):after {
    content: '';
  }
`;

@inject(({ posts, beers, postModal }) => ({
  fetchPost: posts.fetchPost,
  loadBeerList: beers.loadBeerList,
  beers: beers.beers,
  openPostModal: postModal.openPostModal,
}))
@observer
class PostItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      post: props.post,
    };
    this.hideOnBeersTab = window.location.pathname
      .toLowerCase()
      .startsWith('/beers');
  }

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  renderPostSummary() {
    const { post } = this.state;
    var servingType = null;
    if (!!post.servingTypeId && post.servingTypeId > 0) {
      servingType = BeerStoryCore.servingType[post.servingTypeId];
    }
    var price = null;
    if (!!post.price && post.price > 0) {
      price = `￦${post.price.toLocaleString()}`;
    }

    return (
      <div className="bs_text_sub_gray" style={{ margin: '10px 0' }}>
        {!!servingType ? servingType.name : ''}
        {!!servingType ? ' | ' : ''}
        {!!price ? `${price} | ` : ''}
        {!!post.date ? BeerStoryCore.getDateStringFormat(post.date) : ''}
      </div>
    );
  }

  render() {
    const { post } = this.state;
    var { beers, beer, match } = this.props;
    const paramId = match && match.params && match.params.id;
    if (!post || !beers) {
      if (!post) {
        const self = this;
        this.props.fetchPost(paramId, data => {
          if (self.mounted) {
            self.setState({ post: { ...data, id: paramId } });
          }
        });
      }
      if (!beers) {
        this.props.loadBeerList();
      }
      return <BSLoading />;
    }
    if (!beer) {
      beer = beers[post.beerId];
    }
    const user = {
      photoURL: post.userPhotoUrl,
      uid: post.uid,
      displayName: post.userDisplayName,
      creationTime: post.creationTime,
    };

    return (
      <Wrapper paramId={paramId} className="card">
        <Contents>
          <UserInfo>
            <ButtonWrapper>
              <MoreButton
                onClick={() => this.props.openPostModal(post)}
                src={BSImage['ic_more']}
              />
            </ButtonWrapper>
            <UserRow
              reloadProfile={true}
              user={user}
              subInfo={BeerStoryCore.diffDate(post.creationTime)}
            />
          </UserInfo>

          <Summary>
            <Stars>
              <Rating
                readonly={true}
                emptySymbol={<i className="far fa-star" />}
                fullSymbol={<i className="fas fa-star" />}
                fractions={2}
                initialRating={post.rating}
              />
            </Stars>
            {this.renderPostSummary()}
          </Summary>

          {this.hideOnBeersTab === false ? (
            <BeerLabel beer={beer} />
          ) : (
            <div style={{ height: '5px' }} />
          )}

          <FlavorImageWrapper>
            <FlavorChart post={post} />
            <ImageFlavorSliders post={post} />
          </FlavorImageWrapper>

          {post.notes.length > 0 ? (
            <Notes>
              <ShowMoreText lines={!paramId ? 3 : 0} more="더 보기" less="">
                {post.notes.split('\n').map((item, key) => {
                  return (
                    <span key={key}>
                      {item}
                      <br />
                    </span>
                  );
                })}
              </ShowMoreText>
            </Notes>
          ) : (
            ''
          )}
        </Contents>

        <PostFooter post={post} />
      </Wrapper>
    );
  }
}

export default PostItem;
