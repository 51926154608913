import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import styled from 'styled-components';
import { Tab } from 'react-bootstrap';
import { TabItems } from './StyledComponents';
import BeerItem from './BeerItem';
import BSLoading from './BSLoading';

const ListWrapper = styled.div`
  background-color: white;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;
const NoResult = styled.div`
  padding: 50px 10px;
  display: flex;
`;
const Divider = styled.div`
  width: calc(100% - 10px);
  height: 1px;
  margin: 0 5px;
  background-color: #bbb;
`;

@inject(({ beers }) => ({
  loadBeerList: beers.loadBeerList,
  beers: beers.beers,
}))
@observer
class BeerResultList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'popular',
    };
  }

  sortBeers = (beers, sortBy, orderBy = [], count = 10) => {
    var orders = orderBy;
    if (orderBy && sortBy && orderBy.length !== sortBy.length) {
      orders = _.map(sortBy, k => 'desc');
    }
    return _.slice(
      _.orderBy(_.filter(beers, beer => beer.postCount > 0), sortBy, orders),
      0,
      count
    );
  };

  filterByName = (beers, keyword) => {
    return _.filter(
      beers,
      beer =>
        beer.nameKr.includes(keyword) ||
        beer.nameEn.toLowerCase().includes(keyword.toLowerCase())
    );
  };

  filterByBreweryName = (beers, breweryName) => {
    return _.filter(
      beers,
      beer =>
        beer.breweryName &&
        beer.breweryName.toLowerCase().includes(breweryName.toLowerCase())
    );
  };

  generateItemList = () => {
    const { beers, searchKeyword, searchCategory } = this.props;
    const { sortBy } = this.state;
    if (searchKeyword) {
      if (searchCategory === 'beer') {
        return this.filterByName(beers, searchKeyword);
      } else if (searchCategory === 'brewery') {
        return this.filterByBreweryName(beers, searchKeyword);
      }
    } else {
      if (sortBy === 'rating') {
        return this.sortBeers(beers, ['rating', 'postCount', 'creationTime']);
      } else if (sortBy === 'recent') {
        return this.sortBeers(beers, ['creationTime']);
      } else {
        return this.sortBeers(beers, ['postCount', 'creationTime']);
      }
    }
    return [];
  };

  renderBeerList = items => {
    return _.map(items, (beer, idx) => (
      <div key={beer.id}>
        <BeerItem
          beer={beer}
          showIndex={!this.props.searchKeyword}
          index={idx}
        />
        {idx < items.length - 1 && <Divider />}
      </div>
    ));
  };

  render() {
    const { searchKeyword } = this.props;
    const items = this.generateItemList();
    const hasItems = !!items && items.length > 0;

    return (
      <div>
        {!searchKeyword && (
          <TabItems
            fillwithitemcount={3}
            defaultActiveKey={this.state.sortBy}
            id="search_tab_sortby"
            onSelect={sortBy => this.setState({ sortBy })}>
            <Tab eventKey="popular" title="인기순" />
            <Tab eventKey="rating" title="평점순" />
            <Tab eventKey="recent" title="최근 등록순" />
          </TabItems>
        )}
        {!this.props.beers ? (
          <BSLoading />
        ) : (
          <ListWrapper>
            {searchKeyword && !hasItems ? (
              <NoResult>
                <div className="bs_margin_auto">검색 결과가 없습니다.</div>
              </NoResult>
            ) : (
              <div style={{ display: 'block' }}>
                <div>{this.renderBeerList(items)}</div>
              </div>
            )}
          </ListWrapper>
        )}
      </div>
    );
  }
}

export default BeerResultList;
