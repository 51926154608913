import { observable, action } from 'mobx';
import Store from '../Store';

export default class InfoModalStore extends Store {
  @observable data = null;
  @observable dataType = null;

  @action openInfoModal = (data, dataType) => {
    this.data = data;
    this.dataType = dataType;
  };
  @action closeModal = () => {
    this.data = null;
    this.dataType = null;
  };
}
