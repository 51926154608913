import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #fcfcfc;
  border-top: 1px solid #c0c0c0;
  padding: 5px 3px;
`;
const Contents = styled.div`
  padding: 0px 5px;
  b {
    color: #ffc107;
  }
  a {
    color: #3c97f9 !important;
    cursor: pointer;
  }
`;

export default class AboutBS extends Component {
  render() {
    return (
      <Wrapper>
        <Contents>
          <b>BeerStory</b>는 자신이 마신 맥주를 기록하고 공유하는 맥주
          SNS입니다.
          <br />
          <br />
          <b>BeerStory</b>는 맥주에 대한 이야기를 나누고,
          <br />더 맛있는 맥주를 더 맛있게 먹는 법에 대해 고민합니다.
          <br />
          <br />
          <b>BeerStory</b>는 맥주 시음노트 작성 서비스로 시작하여
          <br />
          맥주 검색/추천, 공유, 광고 등의 기능을 점차적으로 추가하여
          <br />
          맥주 포털, 맥주 커뮤니티, 맥주 플랫폼으로의 성장을 도모합니다.
          <br />
          <br />
          <b>BeerStory</b>는 과음을 지양하며,
          <br />
          맛있는 한 잔, 맛있는 한 캔, 맛있는 한 모금을
          <br />더 맛있게 먹기 위함에 그 목적이 있습니다.
          <br />
          <div className="bs_text_sub_gray">
            지나친 음주는 암 발생의 원인이 됩니다. 청소년 음주는 성장과 뇌
            발달을 저해하며, 임신 중 음주는 태아의 기형 발생이나 유산의 위험을
            높입니다.
          </div>
          <br />
          <b>BeerStory</b>의 더 자세한 이야기는 BeerStory 블로그에서 만나볼 수
          있습니다.
          <br />
          <ul>
            <li>
              <a
                href="http://blog.beerstory.io/221480157274"
                target="_blank"
                rel="noopener noreferrer">
                BeerStory 블로그 바로가기
              </a>
            </li>
            <li>
              <a
                href="http://blog.beerstory.io/221507531138"
                target="_blank"
                rel="noopener noreferrer">
                BeerStory Release Note
              </a>
            </li>
          </ul>
        </Contents>
      </Wrapper>
    );
  }
}
