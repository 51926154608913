import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'mobx-react';
import './index.css';

import RootStore from './stores';
import App from './App';

import 'bootstrap4-notify';
import $ from 'jquery';
import 'react-notifications/lib/notifications.css';
// Notification Settings
$.notifyDefaults({
  width: 300,
  delay: 3000,
  placement: { from: 'bottom', align: 'center' },
  offset: { x: 0, y: 0 },
  spacing: 0,
});

export const rootStore = new RootStore();
rootStore.beers.loadBeerList();

ReactDOM.render(
  <div>
    <Provider {...rootStore}>
      <App />
    </Provider>
  </div>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
