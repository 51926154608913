import React, { Component } from 'react';
import styled from 'styled-components';
import { FormControl, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import firebase from '../../include/firebase_core';
import { BeerStoryCore } from '../../include/BeerStoryCore';
import BSLoading from '../common/BSLoading';
import { inject } from 'mobx-react';
import UserStore from '../../stores/UserStore';
import CommentItem from './CommentItem';

const CommentWrapper = styled.div`
  border-top: 1px solid #e0e0e0;
  background-color: #f2f2f2;
  padding: 5px;
  font-size: 1em;
  display: ${props => (props.visible === true ? 'block' : 'none')};
`;
const CommentInput = styled.div`
  border-top: 1px solid #e0e0e0;
  padding-top: 5px;
  display: flex;
  > input {
    line-height: 1em;
    font-size: 1em;
    max-width: 88%;
    margin-right: 2%;
  }
`;
const SubmitButton = styled(Button)`
  min-width: 15%;
  max-width: 75px;
`;

const COMMENTS_LOAD_LIMIT = 20;
@inject(({ user }) => ({
  user: user.user,
}))
class CommentLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: props.post,
      isVisible: false,
      comments: [],
      input: '',
      onUpdate: false,
      onLoading: false,
    };
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
  }
  componentWilUnmount() {
    this.props.onRef && this.props.onRef(undefined);
  }

  loadComments(showProgress = true) {
    if (showProgress) {
      this.setState({ onLoading: true });
    }
    var commentRef = firebase
      .firestore()
      .collection('comments.post')
      .doc(this.state.post.id)
      .collection('comments')
      .orderBy('creationTime', 'desc')
      .limit(COMMENTS_LOAD_LIMIT);
    if (this.state.comments[0]) {
      const lastTime = this.state.comments[0].creationTime;
      commentRef = commentRef.where('creationTime', '<', lastTime);
    }

    commentRef
      .get()
      .then(snapShot => {
        let comments = this.state.comments;
        snapShot.forEach(doc => {
          let comment = doc.data();
          comment.id = doc.id;
          comments.unshift(comment);
        });
        this.setState({ onLoading: false, comments: comments });
      })
      .catch(e => {
        BeerStoryCore.alert_server_connection(e);
        this.setState({ onLoading: false });
      });
  }

  toggle(visible) {
    if (!this.state.isVisible && visible) {
      if (
        this.state.post.commentCount > 0 &&
        this.state.comments.length === 0
      ) {
        this.setState({ isVisible: visible, onLoading: true });
        this.loadComments(false);
        return;
      }
    }
    this.setState({ isVisible: visible });
  }

  handleChange(e) {
    this.setState({ input: e.target.value });
  }
  handleKeyPress(target) {
    if (target.charCode === 13) {
      this.sendComment();
    }
  }

  sendComment() {
    if (UserStore.check_user_and_alert(false)) {
      const { user } = this.props;

      const comment = {
        comment: this.state.input,
        uid: user.uid,
        displayName: user.displayName,
        photoURL: user.photoURL,
        creationTime: new Date().getTime(),
      };

      this.setState({ onUpdate: true });
      var commentRef = firebase
        .firestore()
        .collection('comments.post')
        .doc(this.state.post.id)
        .collection('comments');
      commentRef
        .add(comment)
        .then(data => {
          comment.id = data.id;
          const commentCount = this.state.post.commentCount + 1;
          this.setState({
            post: {
              ...this.state.post,
              commentCount: commentCount,
            },
            onUpdate: false,
            input: '',
            comments: [...this.state.comments, comment],
          });
          this.props.updateCommentSummary(commentCount);
        })
        .catch(e => {
          this.setState({ onUpdate: false });
          BeerStoryCore.alert_server_connection(e);
        });
    }
  }

  renderShowMore() {
    const { post, comments } = this.state;
    let countLeft = post.commentCount - comments.length;
    countLeft = Math.min(COMMENTS_LOAD_LIMIT, countLeft);
    if (!countLeft || countLeft < 1) {
      if (comments.length === 0) {
        return (
          <div style={{ marginBottom: '5px' }} className="bs_text_sub_gray">
            처음으로 댓글을 입력하세요.
          </div>
        );
      }
      return;
    }

    return (
      <div
        style={{ marginBottom: '5px' }}
        onClick={this.loadComments.bind(this)}
        className="bs_text_sub_gray bs_clickable">
        <b>댓글 {countLeft}개 더 보기</b>
      </div>
    );
  }

  confirmDeleteComment(commentId) {
    confirmAlert({
      message: '댓글을 삭제하시겠습니까?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const commentRef = firebase
              .firestore()
              .collection('comments.post')
              .doc(this.state.post.id)
              .collection('comments')
              .doc(commentId);
            commentRef
              .delete()
              .then(data => {
                const commentCount = this.state.post.commentCount - 1;
                this.setState({
                  post: {
                    ...this.state.post,
                    commentCount: commentCount,
                  },
                  comments: this.state.comments.filter(comment => {
                    return comment.id !== commentId;
                  }),
                });
                this.props.updateCommentSummary(commentCount);
              })
              .catch(e => BeerStoryCore.alert_server_connection(e));
          },
        },
        { label: 'No' },
      ],
    });
  }

  renderComments() {
    return this.state.comments.map(comment => {
      return (
        <CommentItem
          key={comment.id ? comment.id : comment.creationTime}
          comment={comment}
        />
      );
    });
  }

  render() {
    return (
      <CommentWrapper visible={this.state.isVisible}>
        {this.state.onLoading ? '' : this.renderShowMore()}
        {this.state.onLoading ? <BSLoading /> : ''}
        {this.renderComments()}

        {this.state.onUpdate ? (
          <BSLoading size={50} />
        ) : (
          <CommentInput>
            <FormControl
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.handleKeyPress.bind(this)}
              value={this.state.input}
              type="text"
              placeholder="댓글을 입력하세요..."
            />
            <SubmitButton
              className="bs_post_comment_btn"
              onClick={this.sendComment.bind(this)}
              disabled={this.state.input.trim().length < 1}
              type="submit"
              bsSize="small"
              bsStyle="info">
              Send
            </SubmitButton>
          </CommentInput>
        )}
      </CommentWrapper>
    );
  }
}

export default CommentLayout;
