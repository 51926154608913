import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CircleNumber from './CircleNumber';
import { inject, observer } from 'mobx-react';

const Wrapper = styled.div`
  padding: 10px 5px;
  background-color: white;
  display: flex;
`;
const Name = styled.div`
  color: #555;
  font-weight: bold;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const AdditionalWrapper = styled.div`
  display: flex;
  margin-top: 2px;
  color: #ffc107;
`;
const Additionals = styled.div`
  display: flex;
  margin-right: 10px;
  font-size: 11px;
  height: 12px;
  line-height: 12px;
  white-space: nowrap;

  color: ${props => (props.color ? props.color : 'inherit')};
  > i {
    margin-right: 2px;
  }
`;

@inject(({ infoModal }) => ({ openInfoModal: infoModal.openInfoModal }))
@observer
class BeerItem extends Component {
  openBreweryModal = () => {
    this.props.openInfoModal(this.props.beer.breweryName, 'brewery');
  };

  render() {
    const { index, beer } = this.props;
    const name = beer.nameEn
      ? beer.nameEn + (beer.nameKr ? '/' + beer.nameKr : '')
      : beer.nameKr;
    const rating = beer.rating > 0 ? (beer.rating * 2).toFixed(2) : '-';
    const postCount = beer.postCount > 0 ? beer.postCount : '-';

    return (
      <Link
        to={`/beers/${beer.id}`}
        onClick={() => window.scrollTo(0, 0)}
        style={{ textDecoration: 'none' }}>
        <Wrapper className="bs_hover_gray bs_clickable">
          {index !== null && !isNaN(index) && this.props.showIndex && (
            <CircleNumber height={20} number={index + 1} />
          )}
          <div style={{ marginLeft: '5px', maxWidth: '92%' }}>
            <Name>{name}</Name>
            {beer.breweryName && (
              <Additionals style={{ color: '#666666' }}>
                Brewed by&nbsp;
                <b>{beer.breweryName}</b>
              </Additionals>
            )}
            <AdditionalWrapper>
              <Additionals>
                <i className="fas fa-star" /> {rating}
              </Additionals>
              <Additionals>
                <i className="fas fa-comment-dots" /> {postCount}
              </Additionals>
              {/* {date && <Additionals color="#bbb">{date}</Additionals>} */}
            </AdditionalWrapper>
          </div>
        </Wrapper>
      </Link>
    );
  }
}

export default BeerItem;
