import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames/bind';
import { BSImage } from '../../res/BSImage';
import { BeerStoryCore } from '../../include/BeerStoryCore';
import UserStore from '../../stores/UserStore';

const HeaderBar = styled.div`
  width: 100% !important;
  top: 0%;
  background: white;
  z-index: 1001;
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  position: fixed;
  display: ${props => (props.bs_app === true ? 'none' : 'block')};
`;
const Nav = styled.nav`
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin: auto;
  @media (min-width: 600px) {
    max-width: 80%;
  }
`;
const HeaderItem = styled.div`
  padding: 8px;
  border-right: 1px solid #d6d6d6;
  width: ${props => props.width || '50%'};
  cursor: pointer;

  :first-child {
    border-left: 1px solid #d6d6d6;
  }
  :hover {
    background: #dfdfdf;
  }
  > img {
    width: 24px;
    height: 24px;
  }
`;

export default class BSHeader extends Component {
  render() {
    const showUserTab = !!UserStore.getCurrentUid();
    const width = `${100 / (showUserTab ? 4 : 3)}%`;

    const path = window.location.pathname;
    return (
      <HeaderBar bs_app={BeerStoryCore.from_bs_app === true}>
        <Route
          render={({ history }) => (
            <Nav>
              <HeaderItem width={width} onClick={() => history.push('/')}>
                <img
                  src={
                    path === '/'
                      ? BSImage['ic_list_active']
                      : BSImage['ic_list']
                  }
                  alt="post"
                />
              </HeaderItem>
              <HeaderItem width={width} onClick={() => history.push('/search')}>
                <img
                  src={
                    path === '/search'
                      ? BSImage['ic_search_active']
                      : BSImage['ic_search']
                  }
                  alt="search"
                />
              </HeaderItem>
              <HeaderItem
                className={classNames({ bs_display_none: !showUserTab })}
                width={width}
                onClick={() => history.push('/users')}>
                <img
                  src={
                    path === '/users'
                      ? BSImage['ic_account_active']
                      : BSImage['ic_account']
                  }
                  alt="account"
                />
              </HeaderItem>
              <HeaderItem
                width={width}
                onClick={() => history.push('/settings')}>
                <img
                  src={
                    path === '/settings'
                      ? BSImage['ic_setting_active']
                      : BSImage['ic_setting']
                  }
                  alt="setting"
                />
              </HeaderItem>
            </Nav>
          )}
        />
      </HeaderBar>
    );
  }
}
