import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 20px;
  text-align: center;
`;
const Input = styled.input`
  width: 100%;
  min-height: 35px;
  padding: 0px 5px;
  border: 1px solid #ffc107;
  border-radius: 8px;
`;

class SearchBar extends Component {
  // One and the only one constructor for React.Component
  constructor(props) {
    super(props);

    // set initial state for this Component(instance)
    this.state = { term: '', hint: props.hint || '' };
  }

  onInputChange(term) {
    this.props.onSearchTermChange(term);
    this.setState({ term });
  }

  render() {
    return (
      <Wrapper>
        <Input
          value={this.state.term}
          placeholder={this.state.hint}
          onChange={event => this.onInputChange(event.target.value)}
        />
      </Wrapper>
    );
  }
  // DO NOT USE this.state.terms = event.target.value
  // Because, React observing this.setState() to check whether state is changed or not.
}

export default SearchBar;
